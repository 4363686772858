<template>
  <div class="tw-flex tw-flex-col tw-justify-between tw-relative tw-py-20 tw-h-full tw-space-y-10 bg-color-primary"
    v-if="!!data && data[index]">
    <div class="tw-flex tw-justify-center tw-py-4">
      <img width="140" height="auto" :src="referrer.logoWhite" alt="">
    </div>
    <div class="tw-flex tw-justify-center">
      <p class=" tw-w-2/4 tw-text-center tw-text-4xl">
        {{ data[index].title }}
      </p>
    </div>
    <div class="tw-flex tw-justify-center tw-font-bold tw-text-3xl"><span>{{ data[index].description }}</span></div>
    <div class="tw-absolute tw-top-72 tw-right-80">
      <svg width="527" height="240" viewBox="0 0 527 240" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M452.321 5.24772L452.328 5.24456L452.335 5.24118C461.053 1.04468 470.89 -0.213532 480.367 1.65534L480.376 1.6569C490.52 3.47103 499.828 8.43059 506.983 15.8337L506.989 15.8397L525.921 34.771L74.4737 235.02L74.4666 235.023L74.4596 235.027C65.7416 239.223 55.905 240.481 46.4272 238.613L46.4184 238.611C36.2746 236.797 26.9664 231.837 19.8111 224.434L19.8111 224.434L19.8051 224.428L0.873794 205.497L452.321 5.24772Z"
          stroke="white" />
      </svg>
    </div>
    <div class="tw-absolute tw-cursor-pointer tw-top-64 tw-right-24"
      @click="$router.push({ name: 'Elearning-quiz-sous-chapitre', params: { chapitre_id: $route.params.id, sous_chapitre_id: 1 } })">
      <svg width="37" height="67" viewBox="0 0 37 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2.73633L33 33.7363L2 64.7363" stroke="white" stroke-width="5" />
      </svg>
    </div>
    <pagination :id="$route.params.id" :data="data" />
  </div>
</template>

<script>
import { Chapitres } from "./mocks/data"
import pagination from "./components/pagination.vue"
import { mapGetters } from "vuex"

export default {
  name: "ElearningQuizChapitre",
  data() {
    return {
      index: 0,
      data: null,
    }
  },
  components: {
    pagination
  },
  mounted() {
    this.data = this.getElearningChapters || Chapitres;
  },
  computed: {
    referrer() {
      return this.$store.state.referrer;
    },
    ...mapGetters(["getElearningChapters"]),
  },
}
</script>

<style></style>
