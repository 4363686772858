<template>
  <v-dialog v-if="handelPopup" v-model="handelPopup" @click:outside="closePopup" width="600"
    transition="dialog-bottom-transition">
    <img class="img-close tw-cursor-pointer" src="@/assets/images/icons/closePopup.svg" alt="closePopup" @click="closePopup"/>
    <div id="deleteDialog" class="tw-p-8">
      <h2 class="tw-font-bold tw-mb-6 tw-text-xl" :style="{ color: theme.ctaBgColor }">Importer un registre de traitement</h2>
      <hr class="tw-border-black" style="width: 175px;" />
      
      <div class="tw-mt-6" style="width: 80%;">
        <TextFieldComponent
          v-model="form.function"
          label="Fonction"
          :error="errors.function != ''"
          :error-messages="errors.function"
          width="100%"
          single-line
          :hide-details="errors.function == ''"
        />
        <TextFieldComponent
          v-model="form.name"
          label="Traitement"
          :error="errors.name != ''"
          :error-messages="errors.name"
          width="100%"
          single-line
          :hide-details="errors.name == ''"
        />
        <TextFieldComponent
          v-model="form.email"
          label="E-mail"
          :error="errors.email != ''"
          :error-messages="errors.email"
          width="100%"
          single-line
          :hide-details="errors.email == ''"
        />

        <vue-dropzone ref="myVueDropzone" id="dropzone" style="padding: 10px 35px; margin-top: 2rem;" :useCustomSlot="true" :options="dropzoneOptions"
          class="upload-file" :includeStyling="false" @vdropzone-removed-file="fileRemoved"
          @vdropzone-file-added="fileAdded">
          <div class="dropzone-custom-content tw-flex tw-flex-col tw-items-center tw-justify-around tw-text-center">
            <span class="title-upload-drpzone" :style="{ color: theme.ctaBgColor }" style="line-height: 2.5;">
              Glisser, déposer ici votre fichier <br />
              Format : jpg, pdf <br />
              Taille max : 10 Mo
            </span>
          </div>
        </vue-dropzone>
      </div>
      
      <div class="tw-flex tw-justify-around tw-w-full">
        <v-btn 
          elevation="0" rounded text large 
          :loading="isSendingForm" @click="isFormValid && submit()"
          :disabled="!isFormValid"
          :style="{ backgroundColor: isFormValid ? theme.ctaBgColor : '#B0B0B0', color: 'white' }"
        >
          <span class="tw-text-white">Valider</span>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import TextFieldComponent from "@/components/common/TextFieldComponent";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { persistRegisterWithFile } from "@/features/ProcessingRegister/services/appApi";
import { mapGetters } from "vuex";
import { validationRules } from "../../../utils/validation-rules";

export default {
  name: "ImportActivityPopup",
  components: {
    TextFieldComponent,
    vueDropzone: vue2Dropzone,
  },
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSendingForm: false,
      form: {
        function: null,
        name: null,
        email: null,
        file: null,
      },
      errors: {
        function: "",
        name: "",
        email: "",
        file: "",
      },
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 50,
        maxFiles: 1,
        dictRemoveFile: `<div class="tw-flex tw-justify-center tw-py-3">
                            <svg class="tw-flex tw-justify-center" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.41 1.28642L10 0L5.705 3.91858L1.41 0L0 1.28642L4.295 5.205L0 9.12358L1.41 10.41L5.705 6.49142L10 10.41L11.41 9.12358L7.115 5.205L11.41 1.28642Z" fill="#2A353A"/>
                            </svg>
                          </div>`,
        addRemoveLinks: true,
        autoProcessQueue: false,
        uploadMultiple: 1,
      },
    };
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
    handelPopup() {
      return this.$parent.canShowImportActivityPopup;
    },
    isFormValid() {
      return (
        Object.values(this.form).every((value) => value !== null) &&
        Object.values(this.errors).every((error) => error === '')
      );
    },
    ...mapGetters({ register: 'getProcessingRegister' }),
  },
  watch: {
    form: {
      handler: function () {
        this.errors.function = !!this.form.function && typeof validationRules.required(this.form.function) === 'string'
          ? validationRules.required(this.form.function) : '';
        
        this.errors.name = !!this.form.name && typeof validationRules.required(this.form.name) === 'string'
          ? validationRules.required(this.form.name) : '';

        this.errors.email = !!this.form.email && typeof validationRules.required(this.form.email) === 'string'
          ? validationRules.required(this.form.email) : '';

        this.errors.email = !!this.form.email && typeof validationRules.email(this.form.email) === 'string'
          ? validationRules.email(this.form.email) : '';
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      this.isSendingForm = true;
      const formData = new FormData();

      formData.append("document", this.form.file);
      formData.append("form", JSON.stringify({ activities: [{
        function: this.form.function, name: this.form.name, email: this.form.email 
      }] }));

      persistRegisterWithFile(formData)
        .then(() => {
          this.$snackbar.showAlertMessage({
            message: "L'activité a été importée avec succès",
            type: "success",
          });
          this.$emit('closeAndFetch');
        })
        .catch(({ response }) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Attention",
            position: "bottom right",
            text: (!!response?.data?.constraints && Object.values(response.data.constraints)[0]) ||
              response?.data?.message || 
              "Une erreur est survenue lors de l'imporation de l'activité",
          });
        })
        .finally(() => this.isSendingForm = false);
    },
    closePopup() {
      this.$emit('close');
      this.$parent.openPopup = false;
    },
    fileRemoved() {
      this.form.file = null;
    },
    fileAdded(file) {
      if (this.form.file) {
        this.$refs.myVueDropzone.removeFile(this.form.file);
      }
      this.form.file = file;
    },
  },
};
</script>
<style lang="scss" scoped>
#deleteDialog {
  position: relative;

  img {
    height: 40px;
    width: 40px;
    margin: 20px auto 20px;
  }

  .img-close {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
