<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" persistent width="45%" content-class="rounded-xl">
    <div class="tw-pt-11 tw-px-9 tw-pb-6">
      <h3 class="tw-font-semibold tw-text-2xl tw-text-center">La formation va être envoyée à cette liste  </h3>
    </div>

    <div class="tw-px-10 tw-h-96 tw-overflow-y-auto tw-mx-auto" style="width: 90%;">
      <ul>
        <li 
          v-for="receiver in receivers" :key="receiver.id"
          class="tw-grid tw-grid-cols-3 tw-py-3 tw-border-b tw-border-black tw-text-xs tw-font-medium"
        >
          <span>{{ receiver.firstName.truncate(20) }}</span> 
          <span>{{ receiver.lastName.truncate(20) }}</span> 
          <span class="tw-justify-self-end">{{ receiver.email }}</span>
        </li>
      </ul>
    </div>

    <div class="tw-flex tw-justify-center tw-gap-x-12 tw-mb-6 tw-mt-9">
      <v-btn width="160px" outlined @click="$emit('close')" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs">Fermer</span></v-btn>

      <v-btn 
        v-if="receivers.length > 0"
        width="160px" elevation="0" rounded
        :loading="isSending" :disabled="isSending" 
        :color="theme.ctaBgColor"
        @click="sendToAll" 
      ><span class="tw-text-xs tw-text-white">Suivant</span></v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'SignatureSuccessPopup',
  props: {
    canOpen: {
      type: Boolean,
      default: false,
    },
    receivers: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      open: false,
      isSending: false,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  watch: {
    canOpen: { handler(val) { this.open = val; }, immediate: true },
  },
  methods: {
    sendToAll() {
      this.isSending = true;

      this.$emit('next');
    }
  }
}
</script>

<style>
.company-text-field.custom-text-field.v-text-field input {
  padding: 0 1rem !important;
  height: 40px !important;
}
</style>