<template>
    <div class="tw-relative tw-flex tw-flex-col tw-items-center">
        <img :src="referer.logo" alt="logo" width="140">
        <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-24 tw-select-none">
            <div class="tw-flex tw-justify-center font-color-primary tw-text-2xl"><span class="tw-mr-2"
                    v-if="userTemp.firstName">{{ userTemp.firstName }}</span> <span
                    v-if="userTemp.lastName">{{ userTemp.lastName }}</span></div>
            <div class="tw-flex tw-justify-center tw-font-bold tw-text-4xl" :class="score">{{ scoreMessage.title }}</div>
            <div class="tw-flex tw-flex-col">
                <div class="tw-flex tw-justify-center">
                    <v-rating length="5" color="yellow" :value="scoreMessage.stars_number" large readonly></v-rating>
                </div>
                <p class="tw-text-center tw-font-bold" v-if="$store.getters.getCorrectAnswers">Vous avez obtenu
                    {{ $store.getters.getCorrectAnswers }} bonnes réponses !</p>

                <div class="tw-flex tw-justify-center tw-mt-6">
                    <p class="tw-text-center tw-w-2/4">{{ scoreMessage.message }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { all_questions } from "../Quiz/mocks/data"
import { mapGetters } from "vuex"

export default {
    name: "ElearningQuizEnd",
    data() {
        return {
            userTemp: {},
            scoreGet: 2
        }
    },
    components: {

    },
    mounted() {
        // setTimeout(() => {this.$router.push({name:'Elearning-quiz-step-one'})}, 5000);
        this.$store.commit('SET_ELERNING_TOKEN', "")
        this.userTemp = this.$store.getters.getTempUser
    },
    computed: {
        scoreMessage() {
            var stars_number = "1";
            var title = "c’est la cata !";
            var message = "il va falloir mouiller votre maillot et vous y mettre si vous ne voulez pas faire prendre des risques à votre entreprise";
            const result = this.$store.getters.getCorrectAnswers;
            const maxScore = (this.getElearningQuestions || all_questions).length;

            if (result > maxScore * 0.2) {
                stars_number = '2';
                title = 'c’est pas terrible !';
                message = 'Il va falloir bucher pour maîtriser un minimum le RGPD, c’est pas perdu si vous vous y mettez.';
            }
            if (result > maxScore * 0.4) {
                stars_number = '3';
                title = 'Peut mieux faire !';
                message = 'Il va falloir renforcer votre connaissance du RGPD mais franchement vous tenez le bon bout.';
            }
            if (result > maxScore * 0.6) {
                stars_number = '4';
                title = 'Pas mal du tout !';
                message = 'On peut voir que vous êtes sensibilisé au RGPD et que votre connaissance est assez bonne.';
            }
            if (result > maxScore * 0.8) {
                stars_number = '5';
                title = 'Super, bravo !';
                message = 'On peut dire que vous vous êtes saisi du RGPD et que votre connaissance est plutôt experte.';
            }

            return { stars_number, title, message }

        },
        score() {
            const result = this.$store.getters.getTotalAnswersCorrects;
            return result < 30 ? 'tw-text-red-500' : result < 65 ? 'tw-text-yellow-500' : 'tw-text-green-500'
        },
        message() {
            const result = this.$store.getters.getTotalAnswersCorrects;
            return result < 30 ? 'Lorem ipsum' : result < 65 ? 'Encore un effort, vous y presque!' : 'Pas mal du tout !'
        },
        referer() {
            return this.$store.state.referrer;
        },
        ...mapGetters(["getElearningQuestions"]),
    },
    methods: {

    }
}
</script>

<style></style>
