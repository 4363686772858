<template>
	<v-container id="sous-traitants" class="px-4 overflow-auto">
		<Breadcrumb divider=">"></Breadcrumb>

		<v-row no-gutters class="mt-4 mb-12">
			<v-col cols="12">
        <EntrepriseHeaderBlockTextComponent
            style="white-space: nowrap"
            title="Liste de vos sous-traitants"
            description=""
            :visibleIcon="false"
        />
      </v-col>

			<v-col cols="12" class="flex-row mt-5 align-center d-flex justify-space-between">
				<TextFieldComponent
          v-model="keyWord"
					class="ml-0 search-input tw-w-2/4"
					label="Rechercher"
					append-icon="mdi-magnify"
					@change="search"
				/>
        <div class="flex-row d-flex align-center">
          <PrimaryButtonComponent
              :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor} !important` }"
              class="export-btn tw-w-1/4"
          >
            <download-excel :data="exportData" :fields="json_fields">
              Exporter <v-icon right dark> mdi-file-move-outline </v-icon>
            </download-excel>
          </PrimaryButtonComponent>

          <PrimaryButtonComponent
              :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor} !important` }"
              class="add-btn tw-w-1/4"
              :to="{ name: 'SubContractor.AddSubContractor' }"
          >
            Inviter
            <v-icon right dark> mdi-plus-circle-outline </v-icon>
          </PrimaryButtonComponent>
        </div>
			</v-col>
		</v-row>

    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <v-simple-table class="subcontractors-table">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="table-sous-th tw-w-2/12">Raison sociale</th>
            <th class="table-sous-th tw-w-4/12">Contact</th>
            <th class="table-sous-th tw-w-3/12">Email</th>
            <th class="table-sous-th tw-w-1/12" style="text-align: center !important;">Score</th>
            <th class="table-sous-th tw-w-1/12" style="text-align: right !important;">Statut</th>
            <th v-if="canHaveActions" class="table-sous-th tw-w-1/12">Actions</th>
          </tr>
          </thead>
          <tbody v-if="table_data.length > 0">
          <tr v-for="item in table_data" :key="item.id">
            <td class="table-sous-td">{{ item.subcontractor.socialReason ? item.subcontractor.socialReason : '' }}</td>
            <td class="table-sous-td">{{ item.subcontractor.user ? item.subcontractor.user.firstName : '' }} {{ item.subcontractor.user ? item.subcontractor.user.lastName : '' }}</td>
            <td class="table-sous-td">{{ item.subcontractor.user ? item.subcontractor.user.email : '' }}</td>
            <td class="table-sous-td tw-text-center" v-html="get_score(item)"></td>
            <td class="table-sous-td tw-text-right">{{ item.status | get_status }}</td>
            <td v-if="canHaveActions" class="table-sous-td">
              <div v-if="!item.subcontractor.isAccountActive" class="tw-w-100 tw-flex tw-justify-center tw-items-center tooltip">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
                </svg>
                <div class="tooltiptext">
                  <ul>
                    <li class="tw-cursor-pointer" @click="resendInvitation(item)">Renvoyer l'invitation</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              Pas de données disponibles
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="currentPage !== lastPage && lastPage !== 0" class="tw-flex tw-flex-col tw-items-center tw-mt-4 tw-mb-4">
        <v-btn
            @click="load_more_data(currentPage + 1)"
            class="mx-2"
            fab
            dark
            x-small
            :color="referer.theme.ctaBgColor"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
        <span
            style="font-style: normal; font-weight: 600; font-size: 12px; line-height: 18px; text-align: center;"
            :style="{color: `${referer.theme.ctaBgColor}`}"
        >Voir plus</span>
      </div>
    </template>

	</v-container>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import {getAllSubContractors, resendInvitation} from "@/features/subcontractor/services/appApi";
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";

export default {
	name: "SubContractors",

	data() {
		return {
      loading: false,
      loadingMore: false,
      currentPage: 1,
      lastPage: 1,
      keyWord: '',
      json_fields: {
        "Raison sociale": 'socialReason',
        "Contact": 'contact',
        "Score": 'score',
        "Statut": 'statut',
      },
			table_data: [],
		};
	},

	components: {
    EntrepriseHeaderBlockTextComponent,
		Breadcrumb,
		PrimaryButtonComponent,
		TextFieldComponent,
	},

	mounted() {
		this.get_subcontractors(`subcontractor?page=${this.currentPage}`)
	},

  computed: {
  referer() {
    return this.$store.state.referrer;
  },
    exportData() {
      return this.table_data.map(item => {
        const score = item.subcontractor.quiz !== null ? item.subcontractor.quiz.scorePercent : '--';
        return {
          socialReason: item.subcontractor.socialReason,
          contact: item.subcontractor.user ? item.subcontractor.user.firstName +' '+ item.subcontractor.user.lastName : '' ,
          score: score,
          statut: this.get_status_export(item.status),
        }
      });
    },
    canHaveActions() {
      const canResendInvitation = this.table_data.some(
        (item) => !item.subcontractor.isAccountActive
      );

      return canResendInvitation;
    }
  },

  filters: {
    get_status(status) {
      if (status === 'in_progress') {
        return 'En cours';
      }
      else if (status === 'waiting') {
        return 'En attente';
      }
      else if (status === 'evaluated') {
        return 'Évalué';
      }
      else {
        return 'Rejetée';
      }
    },
  },

	methods: {
		get_score(item) {
      if (item.status === "waiting" ) {
        return `<span class="v-chip v-size--default" style="background:#A0A0A0; color: #fff;"><span class="v-chip-text"><i aria-hidden="true" class="v-icon mdi mdi-clock-time-three-outline" style="font-size: 16px;"></i></span></span>`
      }
      else if(item.subcontractor.quiz === null || !item.shareDate) {
        return `<span class="v-chip v-size--default" style="background:${this.referer.theme.ctaBgColor}; color: #fff;"><span class="v-chip-text">
                  <svg width="30" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.936 0.516417C15.8549 0.312891 15.6996 0.151156 15.5041 0.0666344C15.408 0.0239542 15.3047 0.00131602 15.2002 0H11.2014C10.9893 0 10.7859 0.0877551 10.6359 0.24396C10.4859 0.400165 10.4016 0.612024 10.4016 0.832931C10.4016 1.05384 10.4859 1.2657 10.6359 1.4219C10.7859 1.57811 10.9893 1.66586 11.2014 1.66586H13.2728L8.80209 6.32195L6.17085 3.57327C6.0965 3.4952 6.00805 3.43324 5.91059 3.39095C5.81313 3.34867 5.70859 3.32689 5.60301 3.32689C5.49744 3.32689 5.3929 3.34867 5.29544 3.39095C5.19798 3.43324 5.10953 3.4952 5.03518 3.57327L0.23657 8.57086C0.161609 8.64829 0.102111 8.74041 0.0615077 8.84191C0.0209045 8.94341 0 9.05228 0 9.16224C0 9.2722 0.0209045 9.38107 0.0615077 9.48256C0.102111 9.58407 0.161609 9.67619 0.23657 9.75362C0.310919 9.83169 0.399374 9.89366 0.496833 9.93594C0.594293 9.97823 0.698827 10 0.804405 10C0.909984 10 1.01452 9.97823 1.11198 9.93594C1.20944 9.89366 1.29789 9.83169 1.37224 9.75362L5.60301 5.33909L8.23425 8.08776C8.3086 8.16583 8.39706 8.22779 8.49451 8.27008C8.59197 8.31237 8.69651 8.33414 8.80209 8.33414C8.90767 8.33414 9.0122 8.31237 9.10966 8.27008C9.20712 8.22779 9.29557 8.16583 9.36992 8.08776L14.4005 2.84029V4.99759C14.4005 5.21849 14.4847 5.43035 14.6347 5.58656C14.7847 5.74276 14.9881 5.83052 15.2002 5.83052C15.4123 5.83052 15.6158 5.74276 15.7658 5.58656C15.9157 5.43035 16 5.21849 16 4.99759V0.832931C15.9987 0.724086 15.977 0.616554 15.936 0.516417Z" fill="white"/>
                  </svg>
                </span></span>`
      }
      else if (item.subcontractor.quiz.status === 'OK_GO') {
        let val = 0;
        if (item.subcontractor.quiz !== null && item.subcontractor.quiz.scorePercent !== null) {
          val = item.subcontractor.quiz.scorePercent.toFixed();
        }
        return `<span class="v-chip v-size--default" style="background:#558C66; color: #fff;"><span class="v-chip-text">${val} / 100</span></span>`
      }
      else if (item.subcontractor.quiz.status === 'GO_SR_A') {
        let val = 0;
        if (item.subcontractor.quiz !== null && item.subcontractor.quiz.scorePercent !== null) {
          val = item.subcontractor.quiz.scorePercent.toFixed();
        }
        return `<span class="v-chip v-size--default" style="background:#D49725; color: #fff;"><span class="v-chip-text">${val} / 100</span></span>`
      }
      else if (item.subcontractor.quiz.status === 'GO_SR_B') {
        let val = 0;
        if (item.subcontractor.quiz !== null && item.subcontractor.quiz.scorePercent !== null) {
          val = item.subcontractor.quiz.scorePercent.toFixed();
        }
        return `<span class="v-chip v-size--default" style="background:#D49725; color: #fff;"><span class="v-chip-text">${val} / 100</span></span>`
      }
      else {
        let val = 0;
        if (item.subcontractor.quiz !== null && item.subcontractor.quiz.scorePercent !== null) {
          val = item.subcontractor.quiz.scorePercent.toFixed();
        }
        return `<span class="v-chip v-size--default" style="background:#BB454C; color: #fff;"><span class="v-chip-text">${val} / 100</span></span>`
      }

      // switch(score) {
			// 	case score >= 70 :
			// 		return `<span class="v-chip v-size--default" style="background:#558C66; color: #fff;"><span class="v-chip__content">${score}</span></span>`
			// 	case score >= 40 :
			// 		return `<span class="v-chip v-size--default" style="background:#D49725; color: #fff;"><span class="v-chip__content">${score}</span></span>`
			// 	case score >= 0 :
			// 		return `<span class="v-chip v-size--default" style="background:#BB454C; color: #fff;"><span class="v-chip__content">${score}</span></span>`
			// 	case score === null :
			// 		return `<span class="v-chip v-size--default" style="background:#A0A0A0; color: #fff;"><span class="v-chip__content"><i aria-hidden="true" class="v-icon mdi mdi-clock-time-three-outline" style="font-size: 16px;"></i></span></span>`
			// 	case score === "en_cours" :
			// 		return `<span class="v-chip v-size--default" style="background:#6D5FD3; color: #fff;"><span class="v-chip__content"><i aria-hidden="true" class="v-icon mdi mdi-chart-line-variant" style="font-size: 16px;"></i></span></span>`
			// 	default:
			// 		return `<span class="v-chip v-size--default"><span class="v-chip__content">${score}</span></span>`
			// }
		},

		get_subcontractors(url) {
    this.loading = true;
			getAllSubContractors(url)
				.then(response => {
          this.table_data = response.data.items;
          this.lastPage = response.data.meta.totalPages;
        })
				.catch((err) => {
          this.$snackbar.showAlertMessage({message: err.response.data.message, type: "error"});
        })
      .finally(() => {
        this.loading = false;
      });
		},

    resendInvitation(subcontractor) {
      resendInvitation({ id: subcontractor.id })
        .then(() => {
          this.$snackbar.showAlertMessage({message: 'Invitation renvoyée avec succès', type: "success"});
        })
        .catch((err) => {
          this.$snackbar.showAlertMessage({message: err.response.data.message, type: "error"});
        })
    },

    load_more_data(page) {
      this.loadingMore = true;
      if (this.keyWord === '') {
        this.currentPage = page;
        let oldData = this.table_data;
        let newData = [];
        getAllSubContractors(`subcontractor?page=${page}`)
            .then(response => {
              newData = response.data.items;
              this.table_data = oldData.concat(newData);
            })
            .catch((err) => {
              this.$snackbar.showAlertMessage({message: err.response.data.message, type: "error"});
            })
            .finally(() => {
              this.loadingMore = false;
            })
      }
      else {
        this.currentPage = page;
        let oldData = this.table_data;
        let newData = [];
        getAllSubContractors(`subcontractor?sirenOrRna=${this.keyWord}&page=${page}`)
            .then(response => {
              newData = response.data.items;
              this.table_data = oldData.concat(newData);
            })
            .catch((err) => {
              this.$snackbar.showAlertMessage({message: err.response.data.message, type: "error"});
            })
            .finally(() => {
              this.loadingMore = false;
            })
      }
    },

		search() {
      if (this.keyWord !== '') {
        this.currentPage = 1;
        this.get_subcontractors(`subcontractor?sirenOrRna=${this.keyWord}&page=1`);
      } else {
        this.currentPage = 1;
        this.get_subcontractors(`subcontractor?page=1`)
      }
		},

		export_file() {
      const rows = [];
      rows.push([
          'Raison sociale', 'Contact', 'Score', 'Statut'
      ]);
      this.table_data.forEach(item => {
        const score = item.subcontractor.quiz !== null ? item.subcontractor.quiz.scorePercent : '--';
        let arr = [
          item.subcontractor.socialReason,
          item.subcontractor.user ? item.subcontractor.user.firstName +' '+ item.subcontractor.user.lastName : '',
          score,
          this.get_status_export(item.status)
        ];
        rows.push(arr);
      })

      let csvContent = "data:text/csv;charset=UTF-8";

      rows.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "export_sous_traitants.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },

    get_status_export(status) {
      if (status === 'in_progress') {
        return 'En attente';
      }
      else if (status === 'waiting') {
        return 'En cours';
      }
      else if (status === 'evaluated') {
        return 'Évalué';
      }
      else {
        return 'Rejetée';
      }
    },
	},
};
</script>

<style>
.subcontractors-table .v-data-table__wrapper { min-height: 500px !important; }
</style>

<style lang="scss" scoped>
.v-application ol,
.v-application ul {
	padding-left: 0 !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477E;
  position: absolute;
  z-index: 1000;
  top: 22px;
  right: -34px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>
