<template>
  <div class="!tw-pt-28">
    <EntrepriseHeaderBlockTextComponent
      title="Signature sécurisée en ligne"
      description="Faites signer vos documents en ligne de manière sécurisée."
    />

    <div class="tw-w-full tw-overflow-auto filter tw-border-2 tw-p-6 tw-rounded-lg border-primary tw-mt-6">
      <SignupSecondaryTextComponent class="subheader tw-mb-6">
        Sélectionnez vos collaborateurs ou sous-traitants
      </SignupSecondaryTextComponent>

      <ReceiversTableComponent ref="receiversTable" :word="word" :filter="filter" />
      
      <div class="tw-mt-6 tw-flex tw-justify-center tw-gap-x-12">
        <v-btn width="160px" outlined @click="backToDocuments" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs">Retour</span></v-btn>
        <v-btn width="160px" @click="next" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs tw-text-white">Suivant</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import ReceiversTableComponent from "@/features/company/components/Signature/ReceiversTable.vue";

export default {
  name: "SignatureSelectionPage",
  components: { 
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    ReceiversTableComponent
  },
  data() {
    return {
      word: "",
      filter: {},
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  methods: {
    backToDocuments() {
      this.$router.push({ name: "Company.Signature.DocumentsList" });
    },
    next() {
      if (
        this.$refs.receiversTable.selectedCollaborators.length === 0 && 
        this.$refs.receiversTable.selectedSubcontractors.length === 0
      ) {
        this.$snackbar.showAlertMessage({
          message: "Veuillez sélectionner au moins un collaborateur ou sous-traitant.",
          type: "error",
        });
        return;
      }

      this.$store.commit("SET_DOCUMENT_TO_BE_SIGNED_RECIEVERS", {
        collaborators: this.$refs.receiversTable.selectedCollaborators,
        subcontractors: this.$refs.receiversTable.selectedSubcontractors,
      });

      this.$router.push({ name: "Company.Signature.Upload" });
    },
  }
}
</script>