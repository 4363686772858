<template>
  <div id="collaborateurs-table">
    <div class="tw-space-x-4">
      <div 
        class="tw-cursor-pointer tw-inline-block tw-mb-8 tw-py-2 tw-rounded-xl tw-border tw-text-center"
        style="width: 260px"
        :style="{ 
          borderColor: selectedReceiversType === receiverType.value ? theme.ctaBgColor : '#4F4F4F',
          color: selectedReceiversType === receiverType.value ? 'white' : '#4F4F4F',
          backgroundColor: selectedReceiversType === receiverType.value ? theme.ctaBgColor : 'white'
        }"
        v-for="receiverType in receiversTypes" :key="receiverType.value"
        @click="selectedReceiversType = receiverType.value"
      >{{ receiverType.text }}</div>
    </div>

    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>

    <template v-else>
      <v-simple-table v-if="selectedReceiversType === 'collaborators'">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="tw-flex tw-items-center tw-justify-center">
                <svg 
                  class="tw-cursor-pointer"
                  @click="selectAllCollaborators"
                  width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="17" height="17" :stroke="theme.ctaBgColor"/>
                  <rect v-if="isAllCollaboratorsSelected" x="2" y="2" width="14" height="14" :fill="theme.ctaBgColor"/>
                </svg>
              </th>
              <th class="!tw-text-center">Collaborateurs</th>
              <th class="!tw-text-center">Fonction</th>
              <th class="!tw-text-center">Email</th>
            </tr>
          </thead>
          <tbody v-if="collaborators.length > 0">
            <tr v-for="collaborator in collaborators" :key="collaborator.id">
              <td class="tw-flex tw-items-center tw-justify-center">
                <svg 
                  class="tw-cursor-pointer"
                  @click="selectCollaborator(collaborator)"
                  width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="17" height="17" :stroke="theme.ctaBgColor"/>
                  <rect v-if="collaborator.isSelected" x="2" y="2" width="14" height="14" :fill="theme.ctaBgColor"/>
                </svg>
              </td>
              <td class="!tw-text-center" :title="collaborator.name">{{ collaborator.name.truncate(30) }}</td>
              <td class="!tw-text-center" :title="collaborator.fonction">{{ collaborator.fonction.truncate(30) }}</td>
              <td class="!tw-text-center" :title="collaborator.email">{{ collaborator.email }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center">
                Pas de données disponibles
              </td>
            </tr>
          </tbody>
          <tfoot class="text-center" v-if="collaboratorsPagesCount > 1">
            <tr>
              <td colspan="5">
                <v-pagination
                  v-model="collaboratorsPage"
                  :length="collaboratorsPagesCount"
                  @input="paginateCollaborators"
                ></v-pagination>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>

      <v-simple-table v-if="selectedReceiversType === 'subcontractors'">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="tw-flex tw-items-center tw-justify-center">
                <svg 
                  class="tw-cursor-pointer"
                  @click="selectAllSubcontractors"
                  width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="17" height="17" :stroke="theme.ctaBgColor"/>
                  <rect v-if="isAllSubcontractorsSelected" x="2" y="2" width="14" height="14" :fill="theme.ctaBgColor"/>
                </svg>
              </th>
              <th class="!tw-text-center">Raison sociale</th>
              <th class="!tw-text-center">SIREN</th>
              <th class="!tw-text-center">Email</th>
            </tr>
          </thead>
          <tbody v-if="subcontractors.length > 0">
            <tr v-for="subcontractor in subcontractors" :key="subcontractor.id">
              <td class="tw-flex tw-items-center tw-justify-center">
                <svg 
                  class="tw-cursor-pointer"
                  @click="selectSubcontractor(subcontractor)"
                  width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="17" height="17" :stroke="theme.ctaBgColor"/>
                  <rect v-if="subcontractor.isSelected" x="2" y="2" width="14" height="14" :fill="theme.ctaBgColor"/>
                </svg>
              </td>
              <td class="!tw-text-center" :title="subcontractor.socialReason">{{ subcontractor.socialReason.truncate(30) }}</td>
              <td class="!tw-text-center" :title="subcontractor.siren">{{ subcontractor.siren.truncate(30) }}</td>
              <td class="!tw-text-center" :title="subcontractor.email">{{ subcontractor.email }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center">
                Pas de données disponibles
              </td>
            </tr>
          </tbody>
          <tfoot class="text-center" v-if="subcontractorsPagesCount > 1">
            <tr>
              <td colspan="5">
                <v-pagination
                  v-model="subcontractorsPage"
                  :length="subcontractorsPagesCount"
                  @input="paginateSubcontractors"
                ></v-pagination>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
import { getAllCollaboratorsPaginate } from "@/features/company/services/appApi";
import { getAllSubContractors } from "@/features/subcontractor/services/appApi";
import { getAllCollaboratorsIds, getAllSubcontractorsIds } from "@/features/company/services/signature.api";

export default {
  name: "ReceiversTableComponent",
  data() {
    return {
      receiversTypes: [
        { value: "collaborators", text: "Collaborateurs" },
        { value: "subcontractors", text: "Sous-traitants" },
      ],
      selectedReceiversType: "collaborators",
      isAllCollaboratorsSelected: false,
      isAllSubcontractorsSelected: false,
      collaborators: [],
      subcontractors: [],
      collaboratorsPage: 1,
      subcontractorsPage: 1,
      collaboratorsPagesCount: 1,
      subcontractorsPagesCount: 1,
      collaboratorsItemsCount: 0,
      subcontractorsItemsCount: 0,
      loading_table: false,
      selectedCollaborators: [],
      selectedSubcontractors: [],
    };
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  async mounted() {
    this.loading_table = true;

    await this.paginateCollaborators();

    await this.paginateSubcontractors();
    
    this.populateFromStore();

    this.loading_table = false;
  },
  methods: {
    async paginateCollaborators(page = this.collaboratorsPage) {
      try {
        const { data } = await getAllCollaboratorsPaginate(page, '', 10, { 
          gouvernanceStatus: 'aucun', ecosystemeStatus: 'aucun', elearningStatus: 'aucun' 
        });
        this.collaborators = data.items.map(item => ({
          id: item.id,
          name: [item.firstName || '', item.lastName || ''].join(' '),
          fonction: item.function || '',
          email: item.email,
          isSelected: this.selectedCollaborators.some((selected) => selected.id === item.id),
        }));

        this.collaboratorsPage = data.meta.currentPage;
        this.collaboratorsPagesCount = data.meta.totalPages;
        this.collaboratorsItemsCount = data.meta.totalItems;
      } catch (error) {
        console.log(error);
        this.$snackbar.showAlertMessage({
          message: "Une erreur s'est produite lors de la récupération des collaborateurs",
          type: "error",
        });
      }
    },
    async paginateSubcontractors(page = this.subcontractorsPage) {
      try {
        const { data } = await getAllSubContractors(`subcontractor?page=${page}`);
        this.subcontractors = data.items.map(item => ({
          id: item.id,
          socialReason: item.subcontractor.socialReason,
          siren: item.subcontractor.sirenOrRna,
          email: item.subcontractor.user.email,
          isSelected: this.selectedSubcontractors.some((selected) => selected.id === item.id),
        }));

        this.subcontractorsPage = data.meta.currentPage;
        this.subcontractorsPagesCount = data.meta.totalPages;
        this.subcontractorsItemsCount = data.meta.totalItems;
      } catch(error) {
        console.log(error);
        this.$snackbar.showAlertMessage({
          message: "Une erreur s'est produite lors de la récupération des sous-traitants",
          type: "error",
        });
      }
    },
    populateFromStore() {
      this.selectedCollaborators = this.$store.state.documentToBeSignedReceivers?.collaborators || [];
      this.selectedSubcontractors = this.$store.state.documentToBeSignedReceivers?.subcontractors || [];

      this.selectedCollaborators.forEach((collaborator) => {
        const index = this.collaborators.findIndex((item) => item.id === collaborator.id);
        if (index !== -1) this.collaborators[index].isSelected = true;
      });

      this.selectedSubcontractors.forEach((subcontractor) => {
        const index = this.subcontractors.findIndex((item) => item.id === subcontractor.id);
        if (index !== -1) this.subcontractors[index].isSelected = true;
      });

      this.isAllCollaboratorsSelected = this.selectedCollaborators.length === this.collaboratorsItemsCount;
      this.isAllSubcontractorsSelected = this.selectedSubcontractors.length === this.subcontractorsItemsCount;
    },
    selectCollaborator(collaborator) {
      if (collaborator.isSelected) {
        this.selectedCollaborators = this.selectedCollaborators.filter((item) => item.id !== collaborator.id);
      } else {
        this.selectedCollaborators.push(collaborator);
      }

      this.collaborators = this.collaborators.map((item) => {
        item.isSelected = item.id === collaborator.id 
          ? !item.isSelected : item.isSelected;
        return item;
      });

      this.isAllCollaboratorsSelected = this.selectedCollaborators.length === this.collaboratorsItemsCount;
    },
    selectSubcontractor(subcontractor) {
      if (subcontractor.isSelected) {
        this.selectedSubcontractors = this.selectedSubcontractors.filter((item) => item.id !== subcontractor.id);
      } else {
        this.selectedSubcontractors.push(subcontractor);
      }

      this.subcontractors = this.subcontractors.map((item) => {
        item.isSelected = item.id === subcontractor.id 
          ? !item.isSelected : item.isSelected;
        return item;
      });

      this.isAllSubcontractorsSelected = this.selectedSubcontractors.length === this.subcontractorsItemsCount;
    },
    selectAllCollaborators() {
      if (this.isAllCollaboratorsSelected) {
        this.selectedCollaborators = [];

        this.collaborators = this.collaborators.map((item) => {
          item.isSelected = false;
          return item;
        });

        this.isAllCollaboratorsSelected = false;
        return;
      }

      getAllCollaboratorsIds()
        .then(({ data }) => {
          this.selectedCollaborators = data.data.map((c) => ({ id: c.collaborateur_id, isSelected: true }));

          this.isAllCollaboratorsSelected = this.selectedCollaborators.length === this.collaboratorsItemsCount;

          this.collaborators = this.collaborators.map((item) => {
            item.isSelected = this.isAllCollaboratorsSelected;
            return item;
          });
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur s'est produite lors de la sélection de tous les collaborateurs",
            type: "error",
          });
        });
    },
    selectAllSubcontractors() {
      if (this.isAllSubcontractorsSelected) {
        this.selectedSubcontractors = [];

        this.subcontractors = this.subcontractors.map((item) => {
          item.isSelected = false;
          return item;
        });

        this.isAllSubcontractorsSelected = false;
        return;
      }

      getAllSubcontractorsIds()
        .then(({ data }) => {
          this.selectedSubcontractors = data.data.map((c) => ({ id: c.clientSubcontractor_id, isSelected: true }));

          this.isAllSubcontractorsSelected = this.selectedSubcontractors.length === this.subcontractorsItemsCount;

          this.subcontractors = this.subcontractors.map((item) => {
            item.isSelected = this.isAllSubcontractorsSelected;
            return item;
          });
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur s'est produite lors de la sélection de tous les sous-traitants",
            type: "error",
          });
        });
    },
  }
}
</script>