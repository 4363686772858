<template>
  <div id="entreprise-header tw-py-1">
    <div class="tw-flex tw-space-x-4 tw-items-center">
      <div class="header" :style="{ fontSize: titleFontSize }" v-html="title"></div>
    </div>
    <span class="mon-entreprise-para" :style="{ fontSize: descriptionFontSize }" v-html="description"></span>
  </div>
</template>

<script>
export default {
  name: "EntrepriseHeaderBlockTextComponent",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    visibleIcon: {
      type: Boolean,
      default: false,
    },
    titleFontSize: {
      type: String,
      default: "24px",
    },
    descriptionFontSize: {
      type: String,
      default: "14px",
    },
  },
};
</script>

<style scoped>
.header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
  /* or 117% */

  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
}
</style>
