<template>
  <div id="elearning-add-container">
    <div class="tw-flex tw-flex-col tw-align-start">
      <EntrepriseHeaderBlockTextComponent title="Inviter un collaborateur à faire le e-learning" />
    </div>
    <div>
      <StepsComponent class="tw-mt-12 tw-w-10/12 tw-mx-auto" :step="3" />
    </div>
    <div class="collaborator-form-container">
      <SignupSecondaryTextComponent class="public-text-register">
        <b>La liste des collaborateurs de l’entreprise.</b>
      </SignupSecondaryTextComponent>
      <div class="collaborators-row">
        <v-row>
          <v-col class="my-4" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Collaborateurs</th>
                    <th>Fonction</th>
                    <th>Email</th>
                    <th style="text-align: center">Score</th>
                    <th style="text-align: center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item.id">
                    <td> {{ item.firstName ? item.firstName + " " + item.lastName : item.collaborateur.firstName +" "+ item.collaborateur.lastName }} </td>
                    <td> {{ item.function ? item.function : item.collaborateur.function }} </td>
                    <td> {{ item.collaborateur.email }} </td>
                    <td>
                      <round :items="item"></round>
                    </td>
                    <td class="tw-cursor-pointer tw-flex tw-justify-center tw-items-center">
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="11" r="10.5" :stroke="referer.theme.ctaBgColor" />
                        <circle cx="5" cy="11" r="2" :fill="referer.theme.ctaBgColor" />
                        <circle cx="11" cy="11" r="2" :fill="referer.theme.ctaBgColor" />
                        <circle cx="17" cy="11" r="2" :fill="referer.theme.ctaBgColor" />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import { getNewCollaborators } from "@/features/company/services/appApi";
import Round from "@/components/common/Round";

export default {
  name: "NewElearningStepTwoPage",
  components: {
    Round,
    SignupSecondaryTextComponent,
    StepsComponent,
    EntrepriseHeaderBlockTextComponent,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      ids: [],
      data: []
    };
  },
  mounted() {
    if (this.IsJsonString(this.$route.params.ids) && Array.isArray(JSON.parse(this.$route.params.ids))) {
      let ids = JSON.parse(this.$route.params.ids);
      if (this.onlyNumbers(ids)) {
        this.ids = ids;
        this.fetchGovernance();
      } else {
        this.$router.replace({ name: 'Elearning' });
      }
    }
    else {
      this.$router.replace({ name: 'Elearning' });
    }
  },
  methods: {
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    onlyNumbers(array) {
      return array.every(element => {
        return typeof element === 'number';
      });
    },
    fetchGovernance() {
      getNewCollaborators('elearning', this.$route.params.ids)
        .then(response => {
          this.data = response.data.data;
        })
        .catch(() => '')
    },
    submit() {
      this.$router.push({ name: 'Elearning.Add.Step2.EMAIL' });
    },
  },
};
</script>
