<template>
  <div id="collaborateurs" class="tw-h-full">
    <EntrepriseHeaderBlockTextComponent
      class="tw-mt-20"
      title="Mon E-learning"
      description="Votre questionnaire, vos scores et vos éventuels axes d’amélioration."
    />

    <div class="tw-border-2 tw-p-6 tw-mt-12 tw-rounded-lg border-primary">
      <div class="tw-flex tw-justify-between tw-mb-2">
        <div class="tw-w-full">
          <SignupSecondaryTextComponent class="subheader tw-mb-6">
            Sélectionnez vos collaborateurs 
          </SignupSecondaryTextComponent>

          <CollaboratorsTable ref="collaboratorsTable" />

          <div class="tw-flex tw-items-center tw-justify-center tw-mt-14 tw-gap-4">
            <v-btn
              elevation="0"
              class="tw-text-white"
              :style="{ border: `1px solid ${theme.ctaBgColor}` }"
              type="Modifier"
              outlined rounded
              @click="$router.push({ name: 'Elearning.InvitationMethod.Select' })"
            >
              <span :style="{ color: theme.ctaBgColor }" class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">Précédent</span>
            </v-btn>

            <v-btn
              elevation="0"
              class="tw-text-white"
              :style="{ backgroundColor: `${theme.ctaBgColor}` }"
              type="Modifier"
              rounded
              @click="showConfirmPopup"
              :disabled="!selectedCollaborators.length"
            >
              <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-6">Suivant</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <ConfirmPopup 
      v-if="canShowConfirmPopup && !!selectedCollaborators.length"
      :can-open="canShowConfirmPopup"
      :receivers="selectedCollaborators"
      @close="canShowConfirmPopup = false"
      @next="storeSelectedCollaborators"
    />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import CollaboratorsTable from "@/features/company/components/Collaborateurs/Elearning/CollaboratorsSelectionTable"
import ConfirmPopup from "@/features/company/components/Collaborateurs/Elearning/CollaboratorsSelectionPopup"

export default {
  name: "NewElearningCollaboratorsSelection",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    CollaboratorsTable,
    ConfirmPopup,
  },
  data() {
    return {
      selectedFormationType: null,
      form: { apeCode: "" },
      canShowConfirmPopup: false,
      selectedCollaborators: []
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    if (
      !this.$store.state.elearningType ||
      !this.$store.state.elearningApeCode
    ) this.$router.push({ name: "Elearning" });

    this.$watch(
      () => this.$refs.collaboratorsTable.selectedCollaborators,
      (newValue) => {
        this.selectedCollaborators = newValue;
      }
    )
  },
  methods: {
    showConfirmPopup() {
      if (!this.selectedCollaborators.length) {
        this.$snackbar.showAlertMessage({
          message: "Veuillez sélectionner au moins un collaborateur",
          type: "error",
        });
        return;
      }

      this.canShowConfirmPopup = true;
    },
    storeSelectedCollaborators() {
      this.$store.commit('addCollaborator', { multi: this.selectedCollaborators });
      this.$router.push({ name: 'Elearning.Add.Step2.EMAIL' })
    }
  }
}
</script>