<template>
  <div id="collaborateurs" class="tw-h-full">
    <EntrepriseHeaderBlockTextComponent
      class="tw-mt-20"
      title="Mon E-learning"
      description="Votre questionnaire, vos scores et vos éventuels axes d’amélioration."
    />

    <div class="tw-border-2 tw-p-6 tw-mt-12 tw-rounded-lg border-primary">
      <div class="tw-flex tw-justify-between tw-mb-2">
        <div class="tw-w-full">
          <SignupSecondaryTextComponent class="subheader tw-mb-6 tw-text-center">
            <span style="font-size: 24px;">Sélectionnez ou invitez vos collaborateurs</span> 
          </SignupSecondaryTextComponent>

          <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-4">
            <div 
              class="tw-rounded tw-p-3 tw-text-center tw-flex tw-flex-col tw-cursor-pointer tw-w-full" 
              style="max-width: 412px;"
              :style="{ 
                border: `1px solid ${ selectedInvitationMethod === 'import' ? theme.ctaBgColor : 'black'  }`,
                backgroundColor: `${ selectedInvitationMethod === 'import' ? theme.ctaBgColor : 'transparent' }`,
                color: selectedInvitationMethod === 'import' ? `#FFFFFF` : `#333333`
              }"
              @click="selectedInvitationMethod = 'import'"
            >
              <span class="tw-font-semibold">Importer vos collaborateurs</span>
              <span class="tw-mt-1 tw-text-sm">Téléchargez une liste ou renseignez manuellement vos collaborateurs.</span>
            </div>

            <div 
              class="tw-rounded tw-p-3 tw-text-center tw-flex tw-flex-col tw-cursor-pointer tw-w-full" 
              style="max-width: 412px;"
              :style="{ 
                border: `1px solid ${ selectedInvitationMethod === 'select' ? theme.ctaBgColor : 'black'  }`,
                backgroundColor: `${ selectedInvitationMethod === 'select' ? theme.ctaBgColor : 'transparent' }`,
                color: selectedInvitationMethod === 'select' ? `#FFFFFF` : `#333333`
              }"
              @click="selectedInvitationMethod = 'select'"
            >
              <span class="tw-font-semibold">Sélectionnez vos collaborateurs</span>
              <span class="tw-mt-1 tw-text-sm">Choisissez des collaborateurs parmi ceux déjà présent dans la plateforme.</span>
            </div>
          </div>

          <div class="tw-flex tw-items-center tw-justify-center tw-mt-14 tw-gap-4">
            <v-btn
              elevation="0"
              class="tw-text-white"
              :style="{ border: `1px solid ${theme.ctaBgColor}` }"
              type="Modifier"
              outlined rounded
              @click="$router.push({ name: 'Elearning.FormationType.Select' })"
            >
              <span :style="{ color: theme.ctaBgColor }" class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">Précédent</span>
            </v-btn>

            <v-btn
              elevation="0"
              class="tw-text-white"
              :style="{ backgroundColor: `${theme.ctaBgColor}` }"
              type="Modifier"
              rounded
              @click="$router.push({ name: nextPathName })"
              :disabled="!selectedInvitationMethod"
            >
              <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-6">Suivant</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";

export default {
  name: "NewElearningInvitationMethodSelection",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
  },
  data() {
    return {
      selectedInvitationMethod: null,
      form: { apeCode: "" }
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
    nextPathName() {
      return (this.selectedInvitationMethod === 'import' && 'Elearning.Add.Step1') ||
        (this.selectedInvitationMethod === 'select' && 'Elearning.Collaborators.Select') || '';
    }
  }
}
</script>