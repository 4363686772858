<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" persistent width="40%" content-class="rounded-xl">
    <div class="tw-py-11 tw-px-9">
      <h3 class="tw-font-semibold tw-text-2xl tw-text-center">Votre document a bien été envoyé !</h3>
      <p class="tw-mt-5 tw-font-medium tw-text-xs tw-w-4/5 tw-text-center tw-mx-auto">Vous pouvez retourner sur la page signature pour envoyer d’autres documents.</p>
    </div>

    <div class="tw-flex tw-justify-center tw-gap-x-12">
      <v-btn width="160px" @click="backToDocuments" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs tw-text-white">Retour</span></v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'SignatureSuccessPopup',
  props: {
    canOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  watch: {
    canOpen: { handler(val) { (this.open = val) }, immediate: true },
  },
  methods: {
    backToDocuments() {
      this.$router.push({ name: "Company.Signature.DocumentsList" });
    },
  },
}
</script>

<style>
.company-text-field.custom-text-field.v-text-field input {
  padding: 0 1rem !important;
  height: 40px !important;
}
</style>