import ApiService from "@/services/api.service";

export const getDocuments = ({ search = "", page = 1 }) => {
  return ApiService.get(`signature/documents?search=${search}&page=${page}`);
}

export const getSignatories = ({ documentId, search = "", page = 1 }) => {
  return ApiService.get(`signature/signatories?documentId=${documentId}&search=${search}&page=${page}`);
}

export const notify = (data) => {
  return ApiService.post(`signature/notify`, data);
}

export const send = (data) => {
  return ApiService.post(`signature/send`, data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export const getAllCollaboratorsIds = () => {
  return ApiService.get(`signature/all-collaborators-ids`);
}

export const getAllSubcontractorsIds = () => {
  return ApiService.get(`signature/all-subcontractors-ids`);
}