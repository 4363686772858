<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" persistent width="40%" content-class="rounded-xl">
    <div class="tw-pt-11 tw-px-9 tw-pb-6">
      <h3 class="tw-font-semibold tw-text-2xl tw-text-center">Votre document a bien été envoyé !</h3>
    </div>

    <div class="tw-flex tw-justify-center tw-gap-x-12 tw-mb-6 tw-mt-9">
      <v-btn width="160px" outlined @click="$emit('close')" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs">Fermer</span></v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'SignatureSuccessSentPopup',
  props: {
    canOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  watch: {
    canOpen: { handler(val) { this.open = val; }, immediate: true },
  },
}
</script>

<style>
.company-text-field.custom-text-field.v-text-field input {
  padding: 0 1rem !important;
  height: 40px !important;
}
</style>