<template>
  <div id="collaborateurs-table">
    <search-text-field-component
      class="tw-mb-3"
      v-model="keyWord"
      label="Rechercher"
      append-icon="mdi-magnify"
      @change="search"
    />

    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>

    <template v-else>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="tw-flex tw-items-center tw-justify-center">
                <svg
                  class="tw-cursor-pointer"
                  @click="selectAll"
                  width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="17" height="17" :stroke="theme.ctaBgColor"/>
                  <rect v-if="isAllSelected" x="2" y="2" width="14" height="14" :fill="theme.ctaBgColor"/>
                </svg>
              </th>
              <th class="!tw-text-center">Collaborateurs</th>
              <th class="!tw-text-center">Fonction</th>
              <th class="!tw-text-center">Email</th>
            </tr>
          </thead>
          <tbody v-if="collaborators.length > 0">
            <tr v-for="collaborator in collaborators" :key="collaborator.id">
              <td class="tw-flex tw-items-center tw-justify-center">
                <svg
                  class="tw-cursor-pointer"
                  @click="select(collaborator)"
                  width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="17" height="17" :stroke="theme.ctaBgColor"/>
                  <rect v-if="collaborator.isSelected" x="2" y="2" width="14" height="14" :fill="theme.ctaBgColor"/>
                </svg>
              </td>
              <td class="!tw-text-center" :title="collaborator.name">{{ collaborator.name.truncate(30) }}</td>
              <td class="!tw-text-center" :title="collaborator.fonction">{{ collaborator.fonction.truncate(30) }}</td>
              <td class="!tw-text-center" :title="collaborator.email">{{ collaborator.email }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center">
                Pas de données disponibles
              </td>
            </tr>
          </tbody>
          <tfoot class="text-center" v-if="pagesCount > 1">
            <tr>
              <td colspan="5">
                <v-pagination
                  v-model="page"
                  :length="pagesCount"
                  @input="paginate"
                ></v-pagination>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
import SearchTextFieldComponent from '@/components/common/SearchTextFieldComponent.vue';
import { getAllCollaboratorsPaginate, getAllCollaboratorsIds } from "@/features/company/services/appApi";

export default {
  name: "CollaboratorsSelectionTable",
  components: {
    SearchTextFieldComponent
  },
  data() {
    return {
      loading_table: false,
      keyWord: "",
      isAllSelected: false,
      pagesCount: 1,
      itemsCount: 0,
      page: 1,
      collaborators: [],
      selectedCollaborators: []
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
    addCollaborator() {
      return this.$store.getters.getAddCollaborator ?? null;
    },
  },
  async mounted() {
    this.loading_table = true;

    await this.paginate();

    this.populateFromStore();

    this.loading_table = false;
  },
  methods: {
    async search() { await this.paginate() },
    async paginate(page = this.page) { 
      try {
        const { data } = await getAllCollaboratorsPaginate(page, this.keyWord, 10, { 
          gouvernanceStatus: 'aucun', ecosystemeStatus: 'aucun', elearningStatus: 'non renseigné' 
        });
        this.collaborators = data.items.map(item => ({
          id: item.id,
          name: [item.firstName || '', item.lastName || ''].join(' '),
          firstName: item.firstName || '',
          lastName: item.lastName || '',
          fonction: item.function || '',
          gender: item.gender,
          email: item.email,
          isSelected: this.selectedCollaborators.some((selected) => selected.id === item.id),
        }));

        this.page = data.meta.currentPage;
        this.pagesCount = data.meta.totalPages;
        this.itemsCount = data.meta.totalItems;
      } catch (error) {
        console.log(error);
        this.$snackbar.showAlertMessage({
          message: "Une erreur s'est produite lors de la récupération des collaborateurs",
          type: "error",
        });
      }
    },
    populateFromStore() {
      this.selectedCollaborators = this.addCollaborator?.multi || [];

      this.selectedCollaborators.forEach((collaborator) => {
        const index = this.collaborators.findIndex((item) => item.id === collaborator.id);
        if (index !== -1) this.collaborators[index].isSelected = true;
      });

      this.isAllSelected = this.selectedCollaborators.length === this.itemsCount;
    },
    select(collaborator) {
      if (collaborator.isSelected) {
        this.selectedCollaborators = this.selectedCollaborators.filter((item) => item.id !== collaborator.id);
      } else {
        this.selectedCollaborators.push(collaborator);
      }

      this.collaborators = this.collaborators.map((item) => {
        item.isSelected = item.id === collaborator.id 
          ? !item.isSelected : item.isSelected;
        return item;
      });

      this.isAllSelected = this.selectedCollaborators.length === this.itemsCount;
    },
    selectAll() { 
      if (this.isAllSelected) {
        this.selectedCollaborators = [];

        this.collaborators = this.collaborators.map((item) => {
          item.isSelected = false;
          return item;
        });

        this.isAllSelected = false;
        return;
      }

      getAllCollaboratorsIds({ gouvernanceStatus: 'aucun', ecosystemeStatus: 'aucun', elearningStatus: 'non renseigné' })
        .then(({ data }) => {
          this.selectedCollaborators = data.map((c) => ({ ...c, isSelected: true }));

          this.isAllSelected = this.selectedCollaborators.length === this.itemsCount;

          this.collaborators = this.collaborators.map((item) => {
            item.isSelected = this.isAllSelected;
            return item;
          });
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur s'est produite lors de la sélection de tous les collaborateurs",
            type: "error",
          });
        });
    },
  }
}
</script>