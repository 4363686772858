<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-px-12 tw-flex tw-flex-col tw-h-full">
      <SignupSecondaryTextComponent class="tw-m-auto tw-w-1/2 public-text-register tw-text-center">
        <b style="color: #f82c2c;">IMPORTANT</b>
        <br /><br /><br />
        Pour accéder au détail de votre évaluation,<br />
        la sauvegarder et être en mesure de la faire évoluer,<br />
        conformément aux dispositions du RGPD, <br />
        <b>vous devez maintenant finaliser votre inscription.</b>
        <br /><br />
        Vous en aurez pour quelques minutes, pas plus.
        <br /><br />
        <b>Un email vous a été envoyé.</b><br />
        Si vous n’avez rien reçu, pensez à vérifier vos indésirables, on ne sait jamais.
      </SignupSecondaryTextComponent>
    </div>
  </div>
</template>

<script>
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";

export default {
  name: "VerifiedSubContractorSuccessPage",
  components: {
    SignupSecondaryTextComponent,
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
}
</script>