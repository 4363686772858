<template>
  <div id="Questionnaire-detail" class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12">
      <div class="tw-flex tw-items-center tw-justify-between">
        <img :src="logo" style="width: 170px;" alt="LOGO" />
        <span @click="logout" class="tw-cursor-pointer" :style="{ color: theme.ctaBgColor }">Se déconnecter</span>
      </div>

      <EntrepriseHeaderBlockTextComponent class="tw-mt-10" title="Back-office"
        :description="socialReason ? `Détail du questionnaire de ${socialReason}.` : ''" />

      <div class="tw-items-center tw-flex tw-flex-col">
        <template v-if="loading">
          <content-placeholders class="marginTop">
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </template>
        <template v-else>
          <div class="!tw-mt-12" id="table" style="width: 60%;">
            <ChapterComponent
              v-for="(item, index) in blueprint.quizChapters"
              :key="item.id"
              :index="parseIndex(index)"
              :chaptre="item"
              :disabled="false"
              :readonly="true"
            ></ChapterComponent>
          </div>
        </template>
  
        <v-btn v-if="!loading" class="tw-mt-4" elevation="1" text rounded large @click="$router.back()">Retour</v-btn>
      </div>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import ChapterComponent from "@/features/company/components/Questionnaire/ChapterReponseComponent";
import Footer from "@/components/layout/Footer";
import { getQuizDetail } from "@/features/gpmse/services/api";

export default {
  name: "GdprQuizGpmseFollowupScoreDetail",
  components: {
    EntrepriseHeaderBlockTextComponent,
    ChapterComponent,
    Footer,
  },
  data() {
    return {
      loading: false,
      blueprint: [],
      selectChapitre: null,
      socialReason: null,
    }
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    parseIndex(index) {
      return typeof index === 'number' ? index : parseInt(index)
    },
    fetchData() {
      this.loading = true;
      getQuizDetail(this.$route.params.userId)
        .then((response) => {
          this.blueprint = response.data.data.quiz;
          this.socialReason = response.data.data.socialReason;
          this.loading = false;

          if (!this.blueprint) this.$router.back();
        })
        .catch((error) => {
          this.loading = false;
          this.$snackbar.showAlertMessage({
            message: error?.response?.data?.message || "Une erreur s'est produite lors de la récupération des données.",
            type: "error"
          });
        });
    },
    handleChapitre(id) {
      if (this.selectChapitre === id) {
        this.selectChapitre = null;
      } else {
        this.selectChapitre = id;
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'LoginPage' });
    },
  },
}
</script>