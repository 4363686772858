var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-flex-col tw-h-full"},[_c('div',{staticClass:"tw-py-6 tw-px-24 tw-h-full tw-flex tw-flex-col tw-w-full tw-mx-auto",staticStyle:{"max-width":"1536px"}},[_c('img',{staticStyle:{"width":"170px"},attrs:{"src":_vm.logo,"alt":"LOGO"}}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-my-auto"},[_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('PrimaryButtonComponent',{staticClass:"tw-mt-8",style:({
            backgroundColor: `${_vm.theme.ctaBgColor} !important`,
            color: `${_vm.theme.ctaTextColor} !important`,
          }),on:{"click":function($event){return _vm.$router.push({ name: 'GdprQuizGpmse.register' })}}},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"500"}},[_vm._v("Je m’évalue")])])],1),_c('img',{staticStyle:{"width":"321px"},attrs:{"src":"/images/gpmse_landing.png","alt":"landing"}})])]),_c('Footer',{staticClass:"tw-w-full tw-mt-auto"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"tw-font-semibold tw-text-3xl"},[_vm._v("Bienvenue sur Viqtor®,")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"tw-font-semibold tw-text-3xl"},[_vm._v("la plateforme souveraine de la conformité RGPD.")]),_c('br'),_c('br'),_c('span',{staticClass:"tw-inline-block tw-font-medium tw-text-xl tw-mt-6"},[_vm._v("À l’initiative du GPMSE,")]),_c('br'),_c('span',{staticClass:"tw-font-medium tw-text-xl"},[_vm._v("vous allez pouvoir évaluer la conformité de votre entreprise.")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"tw-font-medium tw-text-xl"},[_vm._v("Répondre au questionnaire ne vous prendra que quelques minutes.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"tw-mt-8"},[_c('span',{staticClass:"tw-font-light tw-text-xl"},[_vm._v("Une fois que vous l’aurez validé votre score s’affichera automatiquement.")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"tw-font-light tw-text-xl"},[_vm._v("Les points de vigilance ou les « trous dans la raquette » vous seront signalés.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"tw-mt-8"},[_c('span',{staticClass:"tw-font-light tw-text-xl"},[_vm._v("Nous vous souhaitons une bonne évaluation !")])])
}]

export { render, staticRenderFns }