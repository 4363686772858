<template>
  <div id="Questionnaire-container" class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12">
      <img :src="logo" style="width: 170px;" alt="LOGO" />
      
      <EntrepriseHeaderBlockTextComponent
        class="tw-mt-10"
        :title="`Évaluation RGPD de ${socialReason}`"
      />

      <SignupSecondaryTextComponent class="mx-16 public-text-register tw-mt-4 tw-text-center">
        Merci !<br /><br />
        Votre questionnaire d’évaluation est intégralement complété. <br />
        Votre score a été transmis au GPMSE.
      </SignupSecondaryTextComponent>

      <div class="tw-grid tw-gap-x-32 tw-w-full tw-justify-items-center">
        <div class="box-questionnaire">
          <span class="score" style="margin-top: 0;" :class="getColorBadge()">
            {{ total !== null ? total.toFixed() : 0 }} / 100
          </span>

          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-center tw-mx-auto tw-w-full">
            <div class="tw-w-full">
              <div 
                class="tw-flex tw-items-center tw-justify-around tw-font-semibold tw-text-white tw-text-sm tw-rounded-lg"
                :style="{ backgroundColor: `${theme.ctaBgColor} !important` }"
                style="padding: 2px 4px;"
              >
                <span>0</span>
                <span>20</span>
                <span>40</span>
                <span>60</span>
                <span>80</span>
                <span>100</span>
              </div>
              <div style="width: 82%; margin-left: 10px;">
                <svg class="tw-mt-1" :class="getColorBadge()" :style="{ marginLeft: `${total}%` }" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19" /></svg>
              </div>
            </div>

            <span class="tw-text-sm tw-font-bold tw-mt-2" :class="getColorBadge()">{{ scoreMessage.message }}</span>

            <p class="tw-text-sm tw-text-center" style="margin-top: .25rem; line-height: 1rem;">
              {{ scoreMessage.description }}
            </p>
          </div>

          <hr />
          <p style="margin-top: .5rem;">
            Pour accéder aux détails de votre évaluation, <br />
            qui restent des données confidentielles,<br />
            laissez-vous guider.
          </p>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-pb-12 md:tw-pb-20 tw-mt-16">
        <PrimaryButtonComponent
          :style="{
            backgroundColor: `${isSendingForm ? '#F4F4F4' : theme.ctaBgColor} !important`,
            color: `${isSendingForm ? 'black' : theme.ctaTextColor} !important`,
            borderColor: `${isSendingForm ? '#F4F4F4' : theme.ctaBgColor} !important`,
          }"
          :loading="isSendingForm"
          :disabled="isSendingForm"
          @click="saveProgress"
        >Sauvegarder mon évaluation</PrimaryButtonComponent>
      </div>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import Footer from "@/components/layout/Footer";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { registerNotify } from "@/features/auth/services/gpmse.api"

export default {
  name: "GdprQuizGpmseScore",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    Footer,
    PrimaryButtonComponent,
  },
  data() {
    return {
      scoreTotal: null,
      total: 0,
      table_data: [],
      isSendingForm: false,
      scoreMessages: [
        {
          message: "C’est un excellent score !",
          description: `
            Vous êtes dans une excellente dynamique de conformité,
            continuez à maintenir ces bonnes pratiques et
            renouvelez l’exercice régulièrement pour rester en tête.
          `,
          condition: (score) => score >= 85,
        },
        {
          message: "C’est un bon score !",
          description: `
            Quelques améliorations mineures, et davantage de bonnes pratiques,
            vous permettraient d’atteindre une conformité encore plus forte.
            Continuez sur cette voie pour progresser !
          `,
          condition: (score) => score >= 70 && score < 85
        },
        {
          message: "C’est un score encourageant !",
          description: `
            Des améliorations sont nécessaires pour garantir
            une meilleure conformité. En travaillant sur
            quelques points spécifiques, vous pourrez rapidement progresser.
          `,
          condition: (score) => score >= 55 && score < 70
        },
        {
          message: "C’est un score honorable !",
          description: `
            Ce résultat montre que des efforts doivent être faits pour assurer
            une conformité adéquate. Il est essentiel de revoir certaines pratiques
            et de mettre en place des mesures correctives.
          `,
          condition: (score) => score >= 40 && score < 55
        },
        {
          message: "C’est un score frustrant !",
          description: `
            Il est temps d’entreprendre des actions pour rectifier le tir.
            Ce score indique que des failles subsistent en termes de conformité.
            Un plan d’action urgent est nécessaire pour améliorer cette situation.
          `,
          condition: (score) => score < 40
        }
      ]
    };
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
    socialReason() {
      return this.$store.state.gpmseCompanySocialReason;
    },
    scoreMessage() {
      return this.scoreMessages.find((message) => message.condition(this.total));
    }
  },
  mounted() {
    if (
      !this.$store.state.gpmseUserToken ||
      !this.$store.state.gpmseCompanySocialReason
    )
      this.$router.push({ name: "GdprQuizGpmse.register" });

    this.populateFromStore();
  },
  methods: {
    populateFromStore() {
      const quiz = this.$store.getters.getGpmseQuizChaptersWithScore;
      if (!quiz.quizChapters) this.$router.push({ name: "GdprQuizGpmse.register" });

      this.table_data = quiz.quizChapters.sort((a, b) => a.chapterId - b.chapterId);
      this.scoreTotal = quiz.status;
      this.showQuestion = quiz.isDraft;
      this.total = quiz.scorePercent;
    },
    getColorBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-yellow";
      else return "victor-red";
    },
    saveProgress() {
      this.isSendingForm = true;

      registerNotify({ 
        token: this.$store.state.gpmseUserToken || "",
        quizForm: { chapters: this.$store.getters.getGpmseQuizChapters },
      })
      .then(() => {
        this.isSendingForm = false;
        this.$store.commit("SET_GPMSE_USER_TOKEN", null);
        this.$store.commit("SET_GPMSE_QUIZ_CHAPTERS", []);
        this.$store.commit("SET_GPMSE_QUIZ_CHAPTERS_WITH_SCORE", []);
        this.$router.push({ name: "GdprQuizGpmse.success" });
      })
      .catch(err => {
        this.isSendingForm = false;
        console.log(err);
        this.$snackbar.showAlertMessage({
          message: err.response.data.message,
          type: "error",
        });
      });
    },
  }
}
</script>