<template>
  <div id="Questionnaire-rgbd" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-overflow-y-auto tw-w-full tw-max-w-80 tw-py-6">
    <template v-if="loading_qst">
        <content-placeholders class="">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
    </template>
    <template v-else>
      <img width="6%" :src="referer.logo" alt="LOGO" />

      <h3 class="tw-font-semibold tw-text-2xl tw-w-fit tw-mt-8" style="letter-spacing: -0.5px;">Questionnaire RGPD</h3>
        
      <span class="mon-entreprise-para tw-mt-3">Répondez à toutes les questions pour établir votre score.</span>
    
      <div class="tw-w-full marginTop tw-max-w-[60rem] mt-12" id="table">
        <ChapterComponent
          v-for="(chapter, key) in blueprint"
          :key="key"
          :chapter="chapter"
          :chapter-order-number="key+1"
          :saved-chapter="savedChapters[key]"
          :can-show-questions="selectedChapterId == chapter.id"
          @input-changed="checkIsCompleted"
          @selectChapter="selectChapterHandler"
        />
      </div>

      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-20 tw-mt-8">
        <v-btn elevation="1" text rounded large :disabled="!isCompleted" @click="openPopup = true">Valider</v-btn>
      </div>
    </template>

    <AccepterPopup :openPopup="openPopup" @fetchData="valider" @close="openPopup = false" />
  </div>
</template>

<script>
import ChapterComponent from "@/features/company/components/Questionnaire/RGPDChapterComponent";
import AccepterPopup from "@/features/company/components/Questionnaire/AccepterPopup";
import { getPublicQuiBlueprint } from "@/features/company/services/appApi";
import { mapGetters } from "vuex";

export default {
  name: "GDPRPublicQuiz",
  components: {
    ChapterComponent,
    AccepterPopup,
  },
  data() {
    return {
      blueprint: [],
      chapters: [],
      savedChapters: [],
      loading_qst: true,
      selectedChapterId: 0,
      isCompleted: false,
      openPopup: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    ...mapGetters(["getPublicQuizChapters"]),
  },
  mounted() {
    getPublicQuiBlueprint().then((response) => {
      this.blueprint = response.data.data;
      this.chapters = this.blueprint.map((item) => {
        return {
          chapterId: item.id,
          questions: item.questions.map((q) => {
            return {
              questionId: q.id,
              responses: q.responses.map((r) => {
                return {
                  id: r.id,
                  label: r.label,
                  responseId: null,
                };
              }),
            };
          }),
        };
      });
    })
    .catch((err) => {
      this.$snackbar.showAlertMessage({
        message: err.response.data.message,
        type: "error",
      });
    })
    .finally(() => {
      this.loading_qst = false;
    });
    
    // window.addEventListener('keydown', (e) => {
    //   if (e.key === 'f') this.fillAll();
    // });

    setTimeout(() => {
      this.savedChapters = this.getPublicQuizChapters;
      if (this.savedChapters.length > 0) this.chapters = this.savedChapters;
    }, 2000);
  },
  methods: {
    fillAll() {
      this.chapters = this.blueprint.map((item) => {
        return {
          chapterId: item.id,
          questions: item.questions.map((q) => {
            return {
              questionId: q.id,
              responses: q.responses.map((r, index) => {
                return {
                  id: r.id,
                  label: r.label,
                  responseId: index === 0 ? r.id : null,
                };
              }),
            };
          }),
        };
      });
      this.savedChapters = this.chapters;
      this.isCompleted = true;
    },
    selectChapterHandler(chapterId) {
      this.selectedChapterId = this.selectedChapterId == chapterId ? null : chapterId; 
    },
    valider() {
      this.$store.commit("SET_PUBLIC_QUIZ_CHAPTERS", this.chapters);
      this.$router.push({ name: "GdprPublicQuiz.signup" });
    },
    checkIsCompleted(chapterId, questions) {
      this.chapters = this.chapters.map((item) => {
        if (item.chapterId == chapterId) {
          return {
            chapterId,
            questions,
          };
        }
        return item;
      });

      this.isCompleted = this.chapters.filter(
        (m) => m.questions.some(q => q.responses.some(r => !!r.responseId))
      ).length == this.blueprint.length;
    },
  }
};
</script>