<template>
    <div class="tw-flex tw-justify-between tw-pt-24 tw-px-10">
        <span></span>
        <span class="tw-underline tw-cursor-pointer" @click="$router.push({ name: 'Elearning-quiz-question' })">
            Passer au quizz tout de suite
        </span>
        <span class="tw-font-bold tw-text-3xl">{{ id }}/{{ data.length }}</span>
    </div>
</template>

<script>
export default {
    props: ["id", "data"]
}
</script>

<style></style>