<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-w-fit tw-mx-auto">
      <EntrepriseHeaderBlockTextComponent
        class="tw-mt-10"
        title="Questionnaire RGPD"
        description="Quelques informations sont à saisir. Cela ne vous prendra que 2 minutes !"
      />

      <v-form
        ref="form"
        @submit="onSubmit"
        v-model="formValid"
        class="tw-mt-8 tw-w-full"
        autocomplete="off"
      >
        <RadioButtonComponent
          :rules="rules.gender"
          :items="genders"
          :select="form.gender"
          @change="onGenderChange"
        />
        <div class="tw-mt-4 tw-flex tw-space-x-16">
          <TextFieldComponent
            :rules="rules.firstName"
            :error-messages="errors.firstName"
            v-model="form.firstName"
            label="Prénom"
            style="width: 390px;"
          />
          <TextFieldComponent
            :rules="rules.lastName"
            v-model="form.lastName"
            :error-messages="errors.lastName"
            label="Nom"
            style="width: 390px;"
          />
          <TextFieldComponent
            :rules="rules.email"
            v-model="form.email"
            label="Adresse e-mail"
            style="width: 545px;"
          />
        </div>
        <div class="tw-flex tw-space-x-16">
          <TextFieldComponent
            :rules="rules.socialReason"
            v-model="form.socialReason"
            :error-messages="errors.socialReason"
            label="Raison social de l'entreprise"
            style="width: 390px;"
          />
          <TextFieldComponent
            :rules="rules.phone"
            v-model="form.phone"
            :error-messages="errors.phone"
            label="Numéro de téléphone"
            hide-details
            style="width: 390px;"
            @input="autoSpacePhoneNumber"
          />
          <TextFieldComponent
            :rules="rules.exactNbrEmployees"
            v-model="form.exactNbrEmployees"
            :error-messages="errors.exactNbrEmployees"
            label="Nombre de salariés"
            style="width: 200px;"
            type="number"
            min="0"
            @input="() => form.exactNbrEmployees = form.exactNbrEmployees < 0 ? 0 : form.exactNbrEmployees"
          />
          <SelectBoxComponent 
            :rules="rules.nbrEmployees" 
            v-model="form.nbrEmployees" 
            :items="nbrEmployersItems"
            label="Ou tranche du nombre de salarié" 
            style="width: 315px; margin-left: 2rem !important;"
            no-single-line
          />
        </div>
        <div class="tw-flex tw-justify-center tw-pb-12 md:tw-pb-20 tw-mt-10">
          <PrimaryButtonComponent
            :style="{
              backgroundColor: `${formValid ? theme.ctaBgColor : '#F4F4F4'} !important`,
              color: `${formValid ? theme.ctaTextColor : 'black'} !important`,
            }"
            :loading="isSendingForm"
            :disabled="!formValid || isSendingForm"
            type="submit"
          >Valider</PrimaryButtonComponent>
        </div>
      </v-form>
    </div>
  </div>
</template>
<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import RadioButtonComponent from "@/components/common/RadioButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { validationRules } from "@/utils/validation-rules";
import { getUserDataByToken, assistQuizRegister } from "@/features/auth/services/appApi";

export default {
  name: "VerifiedSubContractorRegisterPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    RadioButtonComponent,
    TextFieldComponent,
    PrimaryButtonComponent,
  },
  data() {
    return {
      formValid: false,
      genders: [
        {
          value: "male",
          label: "Monsieur",
        },
        {
          value: "female",
          label: "Madame",
        },
      ],
      nbrEmployersItems: [
        { label: "1 - 2", value: "1-2" },
        { label: "3 - 9", value: "3-9" },
        { label: "10 - 49", value: "10-49" },
        { label: "50 - 99", value: "50-99" },
        { label: "100 - 249", value: "100-249" },
        { label: "250 - 499", value: "250-499" },
        { label: "500 - 2499", value: "500-2499" },
        { label: "2500 - 4999", value: "2500-4999" },
        { label: "5000+", value: "5000+" },
      ],
      form: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        socialReason: "",
        phone: "",
        exactNbrEmployees: null,
        nbrEmployees: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        socialReason: "",
        phone: "",
        exactNbrEmployees: "",
        nbrEmployees: "",
      },
      rules: {
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "lastName"),
        ],
        gender: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "gender"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        socialReason: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "socialReason"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
        nbrEmployees: [
          () => validationRules.asyncError(this.errors, "nbrEmployees"),
        ],
      },
      isSendingForm: false,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    getUserDataByToken(this.$route.params.token)
      .then(({ data }) => {
        this.form.firstName = data?.data?.firstName || "";
        this.form.lastName = data?.data?.lastName || "";
        this.form.email = data?.data?.email || "",
        this.form.gender = data?.data?.gender || "";
        this.form.socialReason = data?.data?.workInCompany?.socialReason || "";
        this.form.phone = data?.data?.phone || "";
        this.form.nbrEmployees = data?.data?.workInCompany?.nbrEmployees || "";
      })
      .catch((error) => {
        console.log(error);
        this.$snackbar.showAlertMessage({
          message: error?.response?.data?.message ||
            "Une erreur est survenue lors de la récupération de vos informations",
          type: "error",
        });
      });
  },
  methods: {
    onGenderChange(value) {
      this.form.gender = value;
    },
    autoSpacePhoneNumber() {
      this.form.phone = this.form.phone.replace(/[^0-9]/g, "");
      this.form.phone = this.form.phone.replace(/(\d{2})(?=\d)/g, "$1 ").trim();
    },
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;

      assistQuizRegister(this.$route.params.token, {
        ...this.form,
        phone: this.form.phone.replace(/\s/g, ""),
      })
        .then(() => {
          this.$store.commit("SET_ASSIST_COMPANY_SOCIAL_REASON", this.form.socialReason);
          this.$router.push({ 
            name: "SubContractor.AddSubContractor.Quiz", 
            params: { token: this.$route.params.token } 
          });
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: (
              (Array.isArray(error?.response?.data?.message) && error?.response?.data?.message[0]) ||
              error?.response?.data?.message ||
              "Une erreur est survenue lors de l'envoi du formulaire"
            ),
            type: "error",
          });
        })
        .finally(() => this.isSendingForm = false);
    },
  }
}
</script>