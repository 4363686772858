import ApiService from "@/services/api.service";

export const getClients = ({ search = "", page = 1 }) => {
  return ApiService.get(`gpmse/admin/clients?search=${search}&page=${page}`);
}

export const getQuiz = (userId) => {
  return ApiService.get(`gpmse/admin/quiz/${userId}`);
}

export const getQuizDetail = (userId) => {
  return ApiService.get(`gpmse/admin/quiz/detail/${userId}`);
}