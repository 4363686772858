<template>
  <div class="!tw-pt-28">
    <EntrepriseHeaderBlockTextComponent
      title="Signature sécurisée en ligne"
      description="Faites signer vos documents en ligne de manière sécurisée."
    />

    <div class="tw-w-full tw-overflow-auto filter tw-border-2 tw-p-6 tw-rounded-lg border-primary tw-mt-6">
      <SignupSecondaryTextComponent class="subheader tw-mb-6">
        Sélectionnez un document
      </SignupSecondaryTextComponent>

      <span class="tw-text-sm tw-font-medium">Déposer un fichier de format PDF ou JPEG dans l’encart prévu à cet effet.</span>

      <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        :useCustomSlot="true"
        :options="dropzoneOptions"
        class="upload-file tw-py-5 tw-w-2/5 tw-mt-20"
        :includeStyling="false"
        @vdropzone-removed-file="fileRemoved"
        @vdropzone-file-added="fileAdded"
      >
        <div class="dropzone-custom-content tw-flex tw-flex-col tw-items-center tw-justify-around">
          <img src="@/assets/images/icons/upload.svg" />
          <span class="title-upload-drpzone tw-mt-2 tw-font-medium">Glisser déposer un fichier</span>
        </div>
      </vue-dropzone>

      <div class="tw-mt-20 tw-flex tw-justify-center tw-gap-x-12">
        <v-btn width="160px" outlined @click="backToSelection" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs">Retour</span></v-btn>
        <v-btn width="160px" @click="next" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs tw-text-white">Suivant</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";

export default {
  name: "SignatureUploadPage",
  components: { 
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      file: null,
      fileBase64: null,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 50,
        maxFiles:1,
        dictRemoveFile: `<div class="tw-flex tw-justify-center tw-py-3">
                            <svg class="tw-flex tw-justify-center" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.41 1.28642L10 0L5.705 3.91858L1.41 0L0 1.28642L4.295 5.205L0 9.12358L1.41 10.41L5.705 6.49142L10 10.41L11.41 9.12358L7.115 5.205L11.41 1.28642Z" fill="#2A353A"/>
                            </svg>
                          </div>`,
        addRemoveLinks: true,
        autoProcessQueue: false,
        uploadMultiple:1
      },
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
    isReceiversSelected() {
      const receivers = this.$store.state.documentToBeSignedReceivers;

      return receivers !== null && 
        (receivers.collaborators.length > 0 || receivers.subcontractors.length > 0);
    }
  },
  mounted() {
    if (!this.isReceiversSelected) {
      this.$router.push({ name: "Company.Signature.DocumentsList" });
      this.$snackbar.showAlertMessage({
        message: "Les destinataires n'ont pas été sélectionnés",
        type: "error",
      });
      return;
    }
  },
  methods: {
    fileRemoved() { this.file = null; },
    fileAdded(file) {
      if (this.file) this.$refs.myVueDropzone.removeFile(this.file);
      this.file = file;
      this.prepareFileBase64();
    },
    backToSelection() {
      this.$router.push({ name: "Company.Signature.Selection" });
    },
    prepareFileBase64() {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.fileBase64 = reader.result;
      }
      reader.onerror = error => {
        console.error('Error: ', error);  
      }
    },
    next() {
      if (!this.fileBase64) {
        this.$snackbar.showAlertMessage({
          message: "Veuillez sélectionner un fichier",
          type: "error",
        });
        return;
      }

      this.$store.commit("SET_DOCUMENT_TO_BE_SIGNED", {
        dataUrl: this.fileBase64,
        filename: this.file.name,
      });
      this.$router.push({ name: "Company.Signature.Preview" });
    }
  }
}
</script>