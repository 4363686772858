<template>
  <div id="collaborateurs-table">
    <div class="tw-flex tw-items-center tw-justify-between tw-mb-3">
      <search-text-field-component
        v-model="keyWord"
        label="Rechercher"
        append-icon="mdi-magnify"
        @change="search"
      />

      <v-btn 
        width="160px" elevation="0" rounded
        :disabled="!canResentToAll || isResending"
        :color="theme.ctaBgColor"
        @click="canOpenSendToAllPopup = true"
      ><span class="tw-text-xs tw-text-white">Renvoyer à tous</span></v-btn>
    </div>

    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>

    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            <th class="!tw-text-center">Statut</th>
            <th class="!tw-text-center">Action</th>
          </tr>
        </thead>
        <tbody v-if="signatories.length > 0">
          <tr v-for="signatory in signatories" :key="signatory.id">
            <td :title="signatory.firstName">{{ signatory.firstname.truncate(30) }}</td>
            <td :title="signatory.lastName">{{ signatory.lastname.truncate(30) }}</td>
            <td :title="signatory.email">{{ signatory.email }}</td>
            <td :title="signatory.signedSignatoriesCount">
              <div
                :class="[statusColor(signatory)]"
                class="tw-text-center tw-rounded-full tw-w-20 tw-text-white tw-mx-auto"
              >{{ status(signatory) }}</div>
            </td>
            <td class="tw-space-x-6 tw-flex tw-items-center tw-justify-center">
              <v-btn 
                :disabled="!signatory.canBeResent || ( isResending && signatory.id === selectedSignatoryId )"
                :loading="( isResending && signatory.id === selectedSignatoryId )"
                @click.stop="resend(signatory.id)" 
                elevation="0" rounded 
                :style="{ backgroundColor: theme.ctaBgColor }"
              ><span class="tw-text-xs tw-text-white">Renvoyer</span></v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              Pas de données disponibles
            </td>
          </tr>
        </tbody>
        <tfoot class="text-center" v-if="length > 1">
          <tr>
            <td colspan="5">
              <v-pagination
                v-model="page"
                :length="length"
                @input="pagination"
              ></v-pagination>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>

    <send-to-all-popup 
      ref="sendToAllPopup"
      v-if="canOpenSendToAllPopup"
      :receivers="signatories.filter(signatory => signatory.canBeResent)"
      :canOpen="canOpenSendToAllPopup" 
      @sendToAll="sendToAll"
      @close="canOpenSendToAllPopup = false"
    />
    <SignatureSuccessSentPopup
      v-if="isSuccessSent"
      :canOpen="isSuccessSent" 
      @close="isSuccessSent = false"
    />
  </div>
</template>

<script>
import SearchTextFieldComponent from '@/components/common/SearchTextFieldComponent.vue';
import SendToAllPopup from '@/features/company/components/Signature/SendToAllPopup.vue';
import SignatureSuccessSentPopup from '@/features/company/components/Signature/SignatureSuccessSentPopup.vue'

import { getSignatories, notify } from '@/features/company/services/signature.api';

export default {
  name: "SignatureSignatoriesTableComponent",
  components: { SearchTextFieldComponent, SendToAllPopup,SignatureSuccessSentPopup },
  data() {
    return {
      signatories: [],
      keyWord: '',
      page: 1,
      length: 1,
      loading_table: false,
      canOpenSendToAllPopup: false,
      isSuccessSent: false,
      documentId: 0,
      isResending: false,
      selectedSignatoryId: 0,
    };
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
    canResentToAll() {
      return this.signatories.some(signatory => signatory.canBeResent);
    }
  },
  mounted() {
    this.fillTable();
  },
  methods: {
    fillTable() {
      this.documentId = this.$route.params.documentId;
    
      this.loading_table = true;
      
      getSignatories({ documentId: this.documentId, search: this.keyWord, page: this.page })
        .then(({data}) => {
          this.signatories = data.signatories || [];
          this.page = data.page || 1;
          this.length = data.length || 1;
          this.loading_table = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading_table = false;
        });
    },
    
    pagination(page) { 
      this.page = page; this.fillTable(); 
    },

    statusColor(signatory) { 
      return signatory.hasSigned ? "tw-bg-green-500" : "tw-bg-[#E8973E]"; 
    },

    status(signatory) { 
      return signatory.hasSigned ? "Signé" : "En cours";
    },

    search() { 
      this.fillTable(); 
    },

    resend(signatoryId) { 
      this.selectedSignatoryId = signatoryId;
      this.isResending = true;

      notify({ documentId: this.documentId, signatoryId })
        .then(() => {
          this.isResending = false;

          this.$snackbar.showAlertMessage({
            message: "Document renvoyé avec succès",
            type: "success",
          })
        })
        .catch(_ => {
          this.isResending = false;

          console.error(_);
          this.$snackbar.showAlertMessage({
            message: "Erreur lors de l'envoi du document",
            type: "error",
          });
        }); 
    },
    
    sendToAll() { 
      notify({ documentId: this.documentId })
        .then(() => {
          this.canOpenSendToAllPopup = false; 
          this.isSuccessSent=true;
          this.$refs.sendToAllPopup.isSending = false;
        })
        .catch(_ => {
          this.$refs.sendToAllPopup.isSending = false;
          console.error(_);
          this.$snackbar.showAlertMessage({
            message: "Erreur lors de l'envoi du document",
            type: "error",
          });
        }); 
    },
  }
}
</script>