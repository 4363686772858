export const validationRules = {
  required: (value) => !!value || `Le champs est requis`,
  email: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "L'email doit être valide",
  passwordMatch: (value, password) =>
    value === password || "le mot de passe ne correspond pas",
  asyncError: (errors, key) => {
    const errorMsg = errors[key];
    errors[key] = "";
    return errorMsg.length <= 0 || errorMsg;
  },
};
