<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-10 tw-min-h-screen tw-relative">
      <v-overlay :value="isLoadingData" class="tw-z-99">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <StepsComponent :step="2" />
      <TitleComponent class="public-title-auth tw-mt-8" text="Complétez votre compte" />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
        <div class="tw-mb-4">Vous y êtes presque !</div>
        <div>
          Nous avons maintenant besoin d’en savoir plus sur votre entreprise.
        </div>
      </SignupSecondaryTextComponent>

      <v-form @submit="onSubmit" v-model="formValid" class="tw-pb-16" autocomplete="off">
        <h3 class="tw-font-bold tw-text-base tw-my-8">
          Informations sur l’entreprise
        </h3>
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <TextFieldComponent :rules="rules.sirenOrRna" v-model="form.sirenOrRna" label="Numéro SIREN / RNA" :disabled="hasAlreadyEnteredPassword" />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <TextFieldComponent :rules="rules.socialReason" v-model="form.socialReason" label="Raison Sociale" />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <TextFieldComponent :rules="rules.tradeName" v-model="form.tradeName" label="Dénomination commerciale"/>
          </v-col>
        </v-row>
        <v-row style="margin-top: -1rem;">
          <v-col cols="12" md="6" lg="4">
            <select-box-component
            :rules="rules.legalStatus" 
            v-model="form.legalStatus" 
            :items="legalStatuses"
            name="legalStatus"
            label="Structure juridique"
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <SelectBoxComponent :rules="rules.nbrEmployees" v-model="form.nbrEmployees" :items="nbrEmployersItems"
              label="Nombre de salariés" />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <TextFieldComponent :rules="rules.corporateOfficer" v-model="form.corporateOfficer"
              label="Mandataire social" />
          </v-col>
        </v-row>
        <v-row style="margin-top: -2rem;">
          <v-col cols="12" md="8" lg="4">
            <TextFieldComponent :rules="rules.address" v-model="form.address" label="Adresse, rue, voie" />
          </v-col>
          <v-col cols="12" md="8" lg="4">
            <TextFieldComponent :rules="rules.zipCode" v-model="form.zipCode" class="tw-col-span-2" label="Code postal" />
          </v-col>
          <v-col cols="12" md="8" lg="4">
            <TextFieldComponent :rules="rules.city" v-model="form.city" class="tw-col-span-2" label="Ville" />
          </v-col>
        </v-row>
        <v-row style="margin-top: -2rem;">
          <v-col cols="12" md="8" lg="4">
            <TextFieldComponent :rules="rules.apeCode" v-model="form.apeCode" label="Code NAF (4 chiffres, 1 lettre)" />
          </v-col>
        </v-row>
        <h3 class="tw-font-bold tw-text-base tw-mb-12 tw-mt-6">
          Informations personnelles
        </h3>
        <v-row style="margin-top: -3rem;">
          <v-col cols="12" md="6" lg="4">
            <TextFieldComponent :rules="rules.service" v-model="form.service" label="Service" />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <TextFieldComponent :rules="rules.role" v-model="form.role" label="Poste / Rôle" />
          </v-col>
        </v-row>
        <h3 v-if="isCommercial || !hasAlreadyEnteredPassword" class="tw-font-bold tw-text-base tw-mb-12 tw-mt-6">
          Créer un mot de passe
        </h3>
        <v-row v-if="isCommercial || !hasAlreadyEnteredPassword" style="margin-top: -3rem;">
          <v-col cols="12" md="6" lg="4">
            <PasswordInputComponent @keyup="handelPassword" v-model="form.password" :rules="rules.password"
              :error-messages="errors.password" label="Mot de passe" validate passwordRules="Au moins 12 caractères, dont 1 majuscule, 1 chiffre, 1 minuscule, 1 caractère
spécial (., @, !, _, %, #, :, $, *, ..., -)" />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <PasswordInputComponent :disabled="disableComfirmPassword" :rules="rules.confirmPassword"
              v-model="form.confirmPassword" :error-messages="errors.confirmPassword"
              label="Vérification de mot de passe" />
          </v-col>
        </v-row>
        <div class="tw-flex tw-mx-6 tw-justify-end tw-mt-14">
          <PrimaryButtonComponent :style="{
            backgroundColor: `${referer.theme.ctaBgColor} !important`,
            color: `${referer.theme.ctaTextColor} !important`
          }" :loading="isSendingForm" :disabled="!formValid || isSendingForm" type="submit">Suivant
            <v-icon
            :style="{
            backgroundColor: `${referer.theme.ctaBgColor} !important`,
            color: `${referer.theme.ctaTextColor} !important`
          }"
            right dark>
              mdi-arrow-right
            </v-icon>
          </PrimaryButtonComponent>
        </div>
      </v-form>
      <p class="tw-absolute tw-bottom-0 registration-iml">
        Les informations à caractère personnel recueillies dans le cadre
        contractuel de votre souscription à la plateforme Viqtor sont
        enregistrées dans un fichier informatisé par la société DBWO pour vous
        permettre l’accès aux services qu’elle propose, assurer l’interaction de
        votre organisation avec ses partenaires ainsi que vous tenir informés de
        l’évolutions de ses prestations. Elles seront conservées tout au long de
        la durée de souscription au service, période durant laquelle elles
        pourront être régulièrement mises à jour à votre demande. Hors demande
        de votre part, elles seront détruites après résiliation de la
        souscription par votre organisation sous un délai de 3 mois après le
        terme du contrat. Ces données sont destinées au personnel administratif
        de DBWO et conservées sur les serveurs de l’entreprise OVH. Conformément
        à la loi « informatique et libertés », vous pouvez exercer votre droit
        d'accès aux données vous concernant et les faire rectifier en contactant
        : dpo@dbwo.legal
      </p>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "../../../components/common/TitleComponent";
import SignupSecondaryTextComponent from "../components/SignupSecondaryTextComponent";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import StepsComponent from "../components/StepsComponent";
import SelectBoxComponent from "../../../components/common/SelectBoxComponent";
import PasswordInputComponent from "../../../components/common/PasswordFieldComponent";
import { validationRules } from "../../../utils/validation-rules";
import { getUserDataByToken, secondStepSignUpApi } from "../services/appApi";
export default {
  name: "SecondStepRegistrationPage",
  components: {
    SelectBoxComponent,
    StepsComponent,
    PrimaryButtonComponent,
    TextFieldComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
    PasswordInputComponent,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      nbrEmployersItems: [
        { label: "1 - 2", value: "1-2" },
        { label: "3 - 9", value: "3-9" },
        { label: "10 - 49", value: "10-49" },
        { label: "50 - 99", value: "50-99" },
        { label: "100 - 249", value: "100-249" },
        { label: "250 - 499", value: "250-499" },
        { label: "500 - 2499", value: "500-2499" },
        { label: "2500 - 4999", value: "2500-4999" },
        { label: "5000+", value: "5000+" },
      ],
      legalStatuses: [
        { header: "PRIVÉE" },
        { name: "Entreprise individuelle (EI)" ,label: "Entreprise individuelle (EI)" },
        { name: "Entreprise unipersonnelle à responsabilité limitée (EURL)" ,label: "Entreprise unipersonnelle à responsabilité limitée (EURL)" },
        { name: "Société à responsabilité limitée (SARL)" ,label: "Société à responsabilité limitée (SARL)" },
        { name: "Société anonyme (SA)" ,label: "Société anonyme (SA)" },
        { name: "Société par actions simplifiée unipersonnelle (SASU)" ,label: "Société par actions simplifiée unipersonnelle (SASU)" },
        { name: "Société par actions simplifiée (SAS)" ,label: "Société par actions simplifiée (SAS)" },
        { name: "Société en nom collectif (SNC)" ,label: "Société en nom collectif (SNC)" },
        { name: "Société en commandite simple (SCS)" ,label: "Société en commandite simple (SCS)" },
        { name: "Société en commandite par actions (SCA)" ,label: "Société en commandite par actions (SCA)" },
        { name: "Société coopérative et participative (SCOP)" ,label: "Société coopérative et participative (SCOP)" },
        { name: "Société Civile Immobilière (SCI)" ,label: "Société Civile Immobilière (SCI)" },
        { name: "Société d’exercice libéral à responsabilité limitée (SELARL)" ,label: "Société d’exercice libéral à responsabilité limitée (SELARL)" },

        { header: "PUBLIQUE" },
        { name: "Établissement public à caractère administratif (EPA)" ,label: "Établissement public à caractère administratif (EPA)" },
        { name: "Établissement public à caractère industriel et commercial (EPIC)" ,label: "Établissement public à caractère industriel et commercial (EPIC)" },
        { name: "Société d'économie mixte (SEM)" ,label: "Société d'économie mixte (SEM)" },
        { name: "Société publique locale (SPL)" ,label: "Société publique locale (SPL)" },
        { name: "Mairie / Établissement public communal (EPC)" ,label: "Mairie / Établissement public communal (EPC)" },

        { header: "ASSOCIATIVE" },
        { name: "Fédération (FED)" ,label: "Fédération (FED)" },
        { name: "Association loi 1901 (AMU)" ,label: "Association loi 1901 (AMU)" },
        { name: "Organisation non gouvernementale (ONG)" ,label: "Organisation non gouvernementale (ONG)" },
        { name: "Association reconnue d'utilité publique (ARUP)" ,label: "Association reconnue d'utilité publique (ARUP)" },
        { name: "Association d'intérêt général (AIG)" ,label: "Association d'intérêt général (AIG)" },
        { name: "Association culturelle (ACU)" ,label: "Association culturelle (ACU)" },
        { name: "Association sportive (ASP)" ,label: "Association sportive (ASP)" },
        { name: "Association caritative (ACA)" ,label: "Association caritative (ACA)" },

        { name: "AUTRE",label: "AUTRE" },
    ],
      formValid: false,
      isLoadingData: true,
      isSendingForm: false,
      isCommercial: false,
      hasAlreadyEnteredPassword: false,
      disableComfirmPassword: true,
      errors: {
        sirenOrRna: "",
        socialReason: "",
        corporateOfficer: "",
        address: "",
        zipCode: "",
        city: "",
        tradeName: "",
        service: "",
        role: "",
        legalStatus: "",
        nbrEmployees: "",
        apeCode: "",
      },
      sirenOrRna: "",
      tradeName: "",
      form: {
        sirenOrRna: "",
        socialReason: "",
        corporateOfficer: "",
        address: "",
        zipCode: "",
        city: "",
        service: "",
        role: "",
        legalStatus: "",
        nbrEmployees: "",
        tradeName: "",
        apeCode: "",
      },
      rules: {
        socialReason: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "socialReason"),
        ],
        corporateOfficer: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "corporateOfficer"),
        ],
        address: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "address"),
        ],
        zipCode: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "zipCode"),
        ],
        city: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "city"),
        ],
        tradeName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "tradeName"),
        ],

        service: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "service"),
        ],
        role: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "role"),
        ],
        legalStatus: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "legalStatus"),
        ],
        nbrEmployees: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "nbrEmployees"),
        ],
        sirenOrRna: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "sirenOrRna"),
        ],
        apeCode: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "apeCode"),
        ],
      },
    };
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      secondStepSignUpApi(this.$route.params.token, this.form)
        .then(() => {
          this.isSendingForm = false;
          this.$router.push({
            name: "signupLast.addCollaborateurs",
            params: { token: this.$route.params.token },
          });
          window.scrollTo(0, 0);
        })
        .catch(({ response }) => {
          window.scrollTo(0, 0);
          this.isSendingForm = false;
          if (response.status === 400) {
            this.errors = { ...this.errors, ...response.data };
            response.data.message.forEach((item) => {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                position: "bottom right",
                text: item,
              });
            });
          }
          this.$refs.form.validate();
        });
    },
    handelPassword(event) {
      if (
        event.target.value.length >= 12 &&
        event.target.value.match(/[a-z]/) &&
        event.target.value.match(/[A-Z]/) &&
        event.target.value.match(/\d+/) &&
        (event.target.value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) || event.target.value.includes('-'))
      ) {
        this.disableComfirmPassword = false;
      } else {
        this.form.confirmPassword = "";
        this.disableComfirmPassword = true;
      }
    }
  },
  mounted() {

    getUserDataByToken(this.$route.params.token)
      .then(({ data: { data } }) => {
        this.isLoadingData = false;
        this.sirenOrRna = data.workInCompany.sirenOrRna;
        this.tradeName = data.workInCompany.tradeName;
        this.form = {
          ...this.form,
          tradeName : data.workInCompany.tradeName || "",
          socialReason: data.workInCompany.socialReason || "",
          corporateOfficer: data.workInCompany.corporateOfficer || "",
          address: data.workInCompany.address || "",
          service: data.service || "",
          role: data.role || "",
          legalStatus: data.workInCompany.legalStatus || "",
          nbrEmployees: data.workInCompany.nbrEmployees || "",
          sirenOrRna: data.workInCompany.sirenOrRna || "",
        };
        
        if (data.workInCompany.isCreatedFromPublicQuiz)
          this.hasAlreadyEnteredPassword = false;
        if (!data.passedStep1NoPwd)
          this.hasAlreadyEnteredPassword = true;

        if (this.isCommercial || !this.hasAlreadyEnteredPassword) {
          this.form.password = "";
          this.form.confirmPassword = "";
          this.errors.password = "";
          this.errors.confirmPassword = "";

          this.rules.password = [
            validationRules.required,
            () => validationRules.asyncError(this.errors, "password"),
          ];

          this.rules.confirmPassword = [
            validationRules.required,
            (value) => validationRules.passwordMatch(value, this.form.password),
          ];
        }

        this.isCommercial = !!data.commercialId;
      })
      .catch(({ response }) => {
        this.isLoadingData = false;
        if (response.status === 404) {
          this.$router.replace({ name: "TokenExpired" });
        } else {
          this.$snackbar.showAlertMessage({
            message: "Erreur: quelque chose s'est mal passé",
            type: "error",
          });
        }
      });
  },
};
</script>

<style scoped>

</style>
