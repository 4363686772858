<template>
  <div>
    <v-radio-group
      class="auth-v-label"
      row v-bind="$attrs" v-on="$listeners"
      v-model="selectedValue"
    >
      <v-radio
        :color="referer.theme.ctaBgColor"
        v-for="item in question"
        :key="item.id"
        :label="item.label"
        :value="item.id"
        :readonly="readonly"
        :disabled="disabled"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioButtonReponseComponent",
  props: {
    question: [Object, Array],
    select: [Number, Array],
    disabled: Boolean,
    readonly: Boolean,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data () {
    return {
      selectedValue: this.select[0]?.id || null,
      selectedId:null
    }
  },
};
</script>

<style scoped></style>
