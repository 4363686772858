import ApiService from "@/services/api.service";

export const checkEcosystemExist = () => {
  return ApiService.get(`collaborator/register/check-ecosystem-exist`);
};

export const getResponsible = () => {
  return ApiService.get(`collaborator/register/get-responsible`);
};
export const getManagerReponsibleDelegateData = () => {
  return ApiService.get(`collaborator/register/get-manager-reponsible-delegate-data`);
};

export const checkRegisterExist = () => {
  return ApiService.get(`collaborator/register/check-register-exist`);
};

export const persistRegisterPriorStep = (data) => {
  return ApiService.post(`collaborator/register/validate-prior-step`, data);
};

export const validateSecondStepRegister = (data) => {
  return ApiService.post(`collaborator/register/validate-second-step`, data);
};

export const persistRegister = (data) => {
  return ApiService.post(`collaborator/register/persist`, data);
};
export const persistRegisterWithFile = (data) => {
  return ApiService.post(`collaborator/register/persistWithFile`, data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const getAllRegisterModulesWithQuestions = () => {
  return ApiService.get(`register-module`);
};

export const getAllRegisterModulesWithQuestionsByToken = (token) => {
  return ApiService.get(`register-module/find-all-by-token/${token}`);
};

export const persistRegisterQuestionsAnswer = (data, token) => {
  return ApiService.post(`register-module-question/persist-answer/${token}`, data);
};

export const updateRegisterUserData = (data, token) => {
  return ApiService.post(`collaborator/register/update-user/${token}`, data);
};

export const getRegisterDataByUser = (id) => {
  return ApiService.get(`register-module/detail-by-user/${id}`);
};

export const persistStatusRegister = (token, value) => {
  return ApiService.post(`register-module/persist-status-register/${token}/${value}`);
};

export const deleteRegister = (id) => {
  return ApiService.delete(`collaborator/delete-register/${id}`);
};

export const updateRegister = (id, module) => {
  return ApiService.put(`collaborator/update-register/${id}`, module);
};

export const deleteEcosystem = (token) => {
  return ApiService.delete(`collaborator/delete-ecosystem/${token}`);
};
