<template>
  <div class="!tw-pt-28">
    <EntrepriseHeaderBlockTextComponent
      title="Signature sécurisée en ligne"
      description="Faites signer vos documents en ligne de manière sécurisée."
    />

    <div class="tw-w-full tw-overflow-auto filter tw-border-2 tw-p-6 tw-rounded-lg border-primary tw-mt-6">
      <SignupSecondaryTextComponent class="subheader tw-mb-6">
        Prévisualiser votre document
      </SignupSecondaryTextComponent>

      <span class="tw-text-sm tw-font-medium">Consulter le document que vous allez faire signer avant de l’envoyer.</span>

      <div class="tw-h-[600px] tw-w-3/5 tw-mx-auto tw-py-5 tw-px-12 tw-bg-[#D9D9D9] tw-overflow-y-auto tw-overflow-x-hidden tw-space-y-4 tw-mt-8">
        <pdf
          v-for="page in pagesCount"
          :key="page"
          :page="page"
          :src="documentToBeSigned"
        ></pdf>
      </div>

      <div class="tw-mt-12 tw-flex tw-justify-center tw-gap-x-12">
        <v-btn width="160px" outlined @click="backToUpload" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs">Retour</span></v-btn>
        <v-btn class="tw-text-white" :loading="isSending" width="160px" @click="send" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs tw-text-white">Envoyer</span></v-btn>
      </div>
    </div>

    <SuccessPopup :canOpen="canOpenSuccessPopup" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import SuccessPopup from "@/features/company/components/Signature/SuccessPopup.vue";
import pdf from 'vue-pdf'

import { send } from "@/features/company/services/signature.api";

export default {
  name: "SignaturePreviewPage",
  components: { 
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    SuccessPopup,
    pdf
  },
  data() {
    return {
      pdfViewer: null,
      documentToBeSigned: null,
      documentNameToBeSigned: null,
      receivers: {
        collaborators: [],
        subcontractors: [],
      },
      pagesCount: 0,
      canOpenSuccessPopup: false,
      isSending: false,
    };
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    if (this.$store.state.documentToBeSigned === null) {
      this.$router.push({ name: "Company.Signature.DocumentsList" });
      this.$snackbar.showAlertMessage({
        message: "Aucun document à signer n'a été trouvé.",
        type: "error",
      });
      return;
    }
    
    this.receivers.collaborators = this.$store.state.documentToBeSignedReceivers.collaborators.map(c => c.id);
    this.receivers.subcontractors = this.$store.state.documentToBeSignedReceivers.subcontractors.map(s => s.id);

    this.documentToBeSigned = this.$store.state.documentToBeSigned;
    this.documentNameToBeSigned = this.$store.state.documentNameToBeSigned;

    this.pdfViewer = pdf.createLoadingTask(this.documentToBeSigned);
    this.pdfViewer.promise.then(pdf => {
      this.setPagesCount(pdf.numPages);
    });
  },
  methods: {
    setPagesCount(count) {
      this.pagesCount = count;
    },
    backToUpload() {
      this.$router.push({ name: 'Company.Signature.Upload' });
    },
    dataURLtoFile(dataURL, fileName) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], fileName, { type: mime });
    },
    send() {
      this.isSending = true;

      const formData = new FormData();

      formData.append("document", this.dataURLtoFile(this.documentToBeSigned, this.documentNameToBeSigned));
      formData.append("form", JSON.stringify({ receivers: this.receivers }));

      send(formData)
        .then(() => {
          this.isSending = false;
          this.canOpenSuccessPopup = true;
          this.$store.commit("SET_DOCUMENT_TO_BE_SIGNED", { dataUrl: null, filename: null });
          this.$store.commit("SET_DOCUMENT_TO_BE_SIGNED_RECIEVERS", null);
        })
        .catch((error) => {
          this.isSending = false;
          console.error(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur s'est produite lors de l'envoi du document.",
            type: "error",
          });
        })
    }
  }
}
</script>