<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    total: {
      type: Number,
      default: 0,
    },
    means: {
      type: Array,
      default: () => [0, 0, 0],
    },
  },
  mounted () {
    this.addPlugin({
      id: 'textCenter',
      beforeDraw: function(chart) {
        var width = chart.chart.width;
        var height = chart.chart.height;
        var ctx = chart.chart.ctx;

        ctx.restore();
        ctx.font = "normal normal bold 20px Poppins";
        ctx.fillStyle = "#716861";
        ctx.textBaseline = "middle";

        var text = chart.data.datasets[0].label;
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = (height + 8) / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    })
    this.renderChart(
      {
        datasets: [
          {
            label: this.total,
            backgroundColor: ['#0FB900', '#FF9900', '#F82C2C'],
            data: this.means,
          },
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: { enabled: false },
        cutoutPercentage: 90,
      }
    )
  }
}
</script>