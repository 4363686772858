<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12 tw-flex tw-flex-col tw-h-full">
      <img :src="logo" style="width: 170px;" alt="LOGO" />
      
      <SignupSecondaryTextComponent class="tw-m-auto tw-w-1/2 public-text-register tw-text-center">
        <b style="color: #f82c2c; font-size: 24px;">IMPORTANT</b>
        <br /><br /><br />
        Nous avons sauvegardé temporairement tout le détail de votre évaluation.
        <br /><br />
        <b>Pour y accéder nous vous avons adressé un email</b><br />
        Laissez-vous guider, vous pourrez vous inscrire gratuitement.
        <br /><br />
        Pensez à vérifier vos courriels indésirables, on ne sait jamais.
      </SignupSecondaryTextComponent>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import Footer from "@/components/layout/Footer";

export default {
  name: "GdprQuizGpmseFollowup",
  components: {
    SignupSecondaryTextComponent,
    Footer,
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
}
</script>