<template>
  <div id="collaborateurs" class="tw-h-full">
    <EntrepriseHeaderBlockTextComponent
      class="tw-mt-20"
      title="Mon E-learning"
      description="Votre questionnaire, vos scores et vos éventuels axes d’amélioration."
    />

    <div class="tw-border-2 tw-p-6 tw-mt-12 tw-rounded-lg border-primary">
      <div class="tw-flex tw-justify-between tw-mb-2">
        <div class="tw-w-full">
          <SignupSecondaryTextComponent class="subheader tw-mb-6 tw-text-center">
            <span style="font-size: 24px;">Sélectionnez le type de formation</span>
            <span class="tw-block tw-font-normal">que vous voulez dispenser aux collaborateurs actifs dans le traitement de la donnée personnelle dans l’entreprise.</span>
          </SignupSecondaryTextComponent>

          <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-4 tw-w-full">
            <div 
              class="tw-rounded tw-p-3 tw-text-center tw-flex tw-flex-col tw-cursor-pointer tw-w-full" 
              style="max-width: 412px;"
              :style="{ 
                border: `1px solid ${ selectedFormationType === 'complete' ? theme.ctaBgColor : 'black'  }`,
                backgroundColor: `${ selectedFormationType === 'complete' ? theme.ctaBgColor : 'transparent' }`,
                color: selectedFormationType === 'complete' ? `#FFFFFF` : `#333333`
              }"
              @click="selectedFormationType = 'complete'"
            >
              <span class="tw-font-semibold">Formation théorique,</span>
              <span class="tw-mt-1 tw-text-sm">aux fondamentaux du RGPD.</span>
            </div>

            <div 
              class="tw-rounded tw-text-center tw-flex tw-flex-col tw-cursor-pointer tw-w-full"
              style="max-width: 412px;"
              :style="{
                border: `1px solid ${ selectedFormationType === 'personalized' ? theme.ctaBgColor : 'black'  }`,
              }"
              @click="selectedFormationType = 'personalized'"
            >
              <div
                class="tw-p-3 tw-text-center tw-flex tw-flex-col tw-w-full"
                :style="{
                  borderBottom: !companyApeCode ? (`1px solid ${ selectedFormationType === 'personalized' ? theme.ctaBgColor : 'black' }`) : 'none',
                  backgroundColor: `${ selectedFormationType === 'personalized' ? theme.ctaBgColor : 'transparent' }`,
                  color: selectedFormationType === 'personalized' ? `#FFFFFF` : `#333333`
                }"
              >
                <span class="tw-font-semibold">Formation pratique personnalisée,</span>
                <span class="tw-mt-1 tw-text-sm">selon l’activité de votre entreprise et de la fonction de chaque collaborateur.</span>
              </div>

              <div v-if="!companyApeCode" class="tw-my-6 tw-flex tw-justify-center">
                <TextFieldComponent style="width: 330px;" v-model="form.apeCode" label="Code APE (NAF)" hide-details />
              </div>
            </div>
          </div>

          <div class="tw-flex tw-items-center tw-justify-center tw-mt-14 tw-gap-4">
            <v-btn
              elevation="0"
              class="tw-text-white"
              :style="{ border: `1px solid ${theme.ctaBgColor}` }"
              type="Modifier"
              outlined rounded
              @click="$router.push({ name: 'Elearning' })"
            >
              <span :style="{ color: theme.ctaBgColor }" class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">Précédent</span>
            </v-btn>

            <v-btn
              elevation="0"
              class="tw-text-white"
              :style="{ backgroundColor: `${theme.ctaBgColor}` }"
              type="Modifier"
              @click="checkApeCode"
              rounded
              :disabled="!selectedFormationType"
              :loading="isCheckingApeCode"
            >
              <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-6">Suivant</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-dialog 
      v-if="canShowActivityConfirmationPopup"
      v-model="canShowActivityConfirmationPopup" 
      transition="dialog-bottom-transition" persistent 
      width="35%" content-class="rounded-xl"
    >
      <div class="tw-pt-8 tw-px-9">
        <h3 class="tw-font-semibold tw-text-2xl tw-text-center">Vous avez renseigné l’activité suivante</h3>
        <p class="tw-mt-8 tw-font-medium tw-text-xs tw-w-4/5 tw-text-center tw-mx-auto">{{ activityName }}</p>
      </div>

      <div class="tw-flex tw-justify-center tw-gap-x-12 tw-w-full tw-py-6">
        <v-btn width="160px" outlined @click="canShowActivityConfirmationPopup = false" elevation="0" style="border-radius: 10px;" :color="theme.ctaBgColor"><span class="tw-text-xs">Retour</span></v-btn>
        <v-btn width="160px" @click="next" elevation="0" style="border-radius: 10px;" :color="theme.ctaBgColor"><span class="tw-text-xs tw-text-white">Confirmer</span></v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import { checkAPEActivity } from "@/features/company/services/appApi";

export default {
  name: "NewElearningFormationTypeSelection",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
  },
  data() {
    return {
      selectedFormationType: null,
      form: { apeCode: null },
      canShowActivityConfirmationPopup: false,
      activityName: '',
      isCheckingApeCode: false
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
    companyApeCode() {
      return this.$store.state.user.workInCompany.apeCode;
    }
  },
  mounted() {
    this.selectedFormationType = this.$store.state.elearningType;
    this.form.apeCode = this.$store.state.elearningApeCode;
  },
  methods: {
    checkApeCode() {
      if (this.selectedFormationType === 'complete' || this.companyApeCode) {
        this.next();
        return;
      }

      this.isCheckingApeCode = true;

      checkAPEActivity(this.form.apeCode)
        .then(({ data }) => {
          this.activityName = data;
          this.isCheckingApeCode = false;
          this.canShowActivityConfirmationPopup = true;
        })
        .catch(error => {
          this.isCheckingApeCode = false;
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: error?.response?.data?.message ||
              "Une erreur s'est produite lors de la vérification du code APE",
            type: "error",
          });
        })
    },
    next() {
      this.$store.commit('SET_ELEARNING_TYPE', this.selectedFormationType);
      this.$store.commit('SET_ELEARNING_APE_CODE', this.companyApeCode || this.form.apeCode);
      this.$router.push({ name: 'Elearning.InvitationMethod.Select' });
    }
  }
}
</script>