<template>
  <div id="register-activity-container">
    <img :src="logo" class="register-activity-logo" alt="LOGO" />
    <div class="!tw-pt-16 !tw-pb-4 !tw-px-40 tw-text-black-100">
      <h4 class="mx-16 tw-font-semibold tw-text-2xl tw-leading-10">{{ tradeName }} , mon registre de traitement {{
        register.registerActivityName
      }}</h4>
      <SignupSecondaryTextComponent class="mx-16 public-text-register tw-mt-4">
        Le registre de traitement est destiné à recenser les traitements de données personnelles mis en œuvre dans
        notre entreprise.<br />
        Centralisé et régulièrement mis à jour, il nous permet de répondre à l’obligation de tenir un registre prévu
        par le RGPD.<br /><br />
        Dans le cadre de l’exercice de vos fonctions vous êtes amené à gérer un processus amenant à traiter des
        données personnelles de personnes physiques. Vous avez été identifié Représentant du Responsable de Traitement
        pour l’activité {{ activity }}<br /><br />
        Avant de renseigner les informations du registre d’activité, merci de vérifier,
        modifier ou compléter ces informations qui vous concernent.
      </SignupSecondaryTextComponent>
      <RegisterActivityForm class="tw-mt-8" :register="register" ref="registerActivityForm" />
      <SignupSecondaryTextComponent class="public-text-register tw-my-12 tw-w-3/4 tw-mx-auto">
        Commencez maintenant à compléter votre fiche de registre de traitement en renseignant les rubriques ci-après
      </SignupSecondaryTextComponent>
      <RegisterActivity 
        :activity="register.registerActivityName" 
        :user="register.collaborateur"
        @submit="submitRegister"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import RegisterActivityForm from "@/features/ProcessingRegister/components/RegisterActivityForm";
import RegisterActivity from "@/features/ProcessingRegister/components/RegisterActivity";
import { getNewCollaboratorByToken } from "@/features/company/services/appApi";

export default {
  name: "RegisterActivityWithToken",
  components: { RegisterActivity, RegisterActivityForm, SignupSecondaryTextComponent },
  computed: {
    ...mapGetters({
    }),
    tradeName() {
      if (this.register.collaborateur && this.register.collaborateur.company) {
        return this.register.collaborateur.company.tradeName;
      } else {
        return "";
      }

    },
    logo() {
      return this.$store.state.referrer.logo;
    },
  },
  data() {
    return {
      isDataLoading: false,
      register: {},
      activity: '',
    }
  },
  mounted() {
    this.getRegisterByToken();
  },
  methods: {
    getRegisterByToken() {
      this.isDataLoading = true;
      getNewCollaboratorByToken('register', this.$route.params.token)
        .then(response => {
          if (!response.data.data) {
            this.$router.replace({ name: 'landing-public' });
          } else {
            this.register = response.data.data;
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.isDataLoading = false);
    },
    submitRegister() {
      if (!this.$refs.registerActivityForm.isDataValidated) {
        this.$snackbar.showAlertMessage({ type: 'error', message: "Veuillez validez les informations avant de continuer" })
        return;
      }

      this.$router.replace({ name: 'Register.Activity.Token.Finish', params: { token: this.$route.params.token } });
    }
  },
}
</script>
<style lang="scss" scoped>
#register-activity-container {
  max-width: 1400px;
  margin: 0 auto;

  .register-activity-logo {
    margin-top: 20px;
    width: 14rem;
    height: auto;
  }

  .register-activity-footer-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--cta-bg-color);
  }

  .register-activity-footer-button {
    width: 210px !important;
    padding: 8px 32px !important;
  }
}
</style>
