<template>
  <div id="collaborateurs-table">
    <search-text-field-component
      class="tw-mb-3"
      v-model="keyWord"
      label="Rechercher"
      append-icon="mdi-magnify"
      @change="search"
    />

    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>

    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="tw-w-2/5">Document</th>
            <th class="!tw-text-center">Signataires</th>
            <th class="!tw-text-center">Statut</th>
            <th class="!tw-text-center">Envoyé</th>
            <th class="!tw-text-center">Action</th>
          </tr>
        </thead>
        <tbody v-if="documents.length > 0">
          <tr 
            class="tw-cursor-pointer"
            v-for="document in documents" :key="document.id"
            @click="goToDocument(document.id)"
          >
            <td :title="document.name">{{ document.name.truncate(30) }}</td>
            <td class="!tw-text-center" :title="document.signatoriesCount"><div class="tw-mx-auto tw-text-center tw-border tw-border-black tw-rounded-full tw-w-16">{{ document.signatoriesCount }}</div></td>
            <td :title="document.signedSignatoriesCount">
              <div
                :class="isSigned(document) ? 'tw-bg-green-500' : 'tw-bg-[#E8973E]'"
                class="tw-text-center tw-rounded-full tw-w-20 tw-text-white tw-mx-auto"
              >{{ status(document) }}</div>
            </td>
            <td class="!tw-text-center" :title="document.sentAt">{{ document.sentAt }}</td>
            <td class="tw-space-x-6 tw-flex tw-items-center tw-justify-center">
              <v-btn @click.stop="download(document.link, document.name)" elevation="0" rounded :style="{ backgroundColor: theme.ctaBgColor }">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 11.4141L2.97656 6.89063L3.98438 5.88281L6.79688 8.69531V0H8.20312V8.69531L11.0156 5.88281L12.0234 6.89063L7.5 11.4141ZM1.40625 15C1.03125 15 0.703125 14.8594 0.421875 14.5781C0.140625 14.2969 0 13.9688 0 13.5938V10.2422H1.40625V13.5938H13.5938V10.2422H15V13.5938C15 13.9688 14.8594 14.2969 14.5781 14.5781C14.2969 14.8594 13.9688 15 13.5938 15H1.40625Z" fill="white"/>
                </svg>
              </v-btn>
              <v-btn 
                :disabled="!document.canBeResent || ( isResending && document.id === selectedDocumentId )" 
                :loading="( isResending && document.id === selectedDocumentId )"
                :style="{ backgroundColor: theme.ctaBgColor, color: 'white' }"
                elevation="0" rounded 
                @click.stop="resend(document.id)" 
              ><span class="tw-text-xs tw-text-white">Renvoyer</span></v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              Pas de données disponibles
            </td>
          </tr>
        </tbody>
        <tfoot class="text-center" v-if="length > 1">
          <tr>
            <td colspan="5">
              <v-pagination
                v-model="page"
                :length="length"
                @input="pagination"
              ></v-pagination>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import SearchTextFieldComponent from '@/components/common/SearchTextFieldComponent.vue';

import { getDocuments, notify } from '@/features/company/services/signature.api';

export default {
  name: "SignatureDocumentsTableComponent",
  components: { SearchTextFieldComponent },
  data() {
    return {
      documents: [],
      keyWord: '',
      page: 1,
      length: 1,
      loading_table: false,
      isResending: false,
      selectedDocumentId: 0,
    };
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    this.fillTable();
  },
  methods: {
    fillTable() { 
      this.loading_table = true;
      
      getDocuments({ search: this.keyWord, page: this.page })
        .then(({ data }) => {
          this.documents = data.documents || [];
          this.page = data.page || 1;
          this.length = data.length || 1;
          this.loading_table = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading_table = false;
        }); 
    },
    
    pagination(page) { 
      this.page = page; this.fillTable(); 
    },
    
    search() { 
      this.fillTable(); 
    },

    download(documentLink, documentName) { 
      const link = document.createElement("a");
      link.href = documentLink;
      link.download = documentName;
      link.target = "_blank";
      link.click();
    },
    
    isSigned(document) { 
      return document.signatoriesCount === document.signedSignatoriesCount; 
    },

    status(document) { 
      return this.isSigned(document)
        ? "Signé" : `${document.signedSignatoriesCount}/${document.signatoriesCount}`; 
    },

    goToDocument(documentId) {
      this.$router.push({ name: "Company.Signature.SignatoriesList", params: { documentId } });
    },
    
    resend(documentId) { 
      this.isResending = true;
      this.selectedDocumentId = documentId;

      notify({ documentId })
        .then(() => {
          this.isResending = false;

          this.$snackbar.showAlertMessage({
            message: "Document renvoyé avec succès",
            type: "success",
          })
        })
        .catch(_ => {
          this.isResending = false;
          
          console.error(_);
          this.$snackbar.showAlertMessage({
            message: "Erreur lors de l'envoi du document",
            type: "error",
          });
        });
    },
  }
}
</script>