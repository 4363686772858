var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-h-full",attrs:{"id":"collaborateurs"}},[_c('EntrepriseHeaderBlockTextComponent',{staticClass:"tw-mt-20",attrs:{"title":"Mon E-learning","description":"Votre questionnaire, vos scores et vos éventuels axes d’amélioration."}}),_c('div',{staticClass:"tw-border-2 tw-p-6 tw-mt-12 tw-rounded-lg border-primary"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-mb-2"},[_c('div',{staticClass:"tw-w-full"},[_c('SignupSecondaryTextComponent',{staticClass:"subheader tw-mb-6 tw-text-center"},[_c('span',{staticStyle:{"font-size":"24px"}},[_vm._v("Sélectionnez ou invitez vos collaborateurs")])]),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-4"},[_c('div',{staticClass:"tw-rounded tw-p-3 tw-text-center tw-flex tw-flex-col tw-cursor-pointer tw-w-full",staticStyle:{"max-width":"412px"},style:({ 
              border: `1px solid ${ _vm.selectedInvitationMethod === 'import' ? _vm.theme.ctaBgColor : 'black'  }`,
              backgroundColor: `${ _vm.selectedInvitationMethod === 'import' ? _vm.theme.ctaBgColor : 'transparent' }`,
              color: _vm.selectedInvitationMethod === 'import' ? `#FFFFFF` : `#333333`
            }),on:{"click":function($event){_vm.selectedInvitationMethod = 'import'}}},[_c('span',{staticClass:"tw-font-semibold"},[_vm._v("Importer vos collaborateurs")]),_c('span',{staticClass:"tw-mt-1 tw-text-sm"},[_vm._v("Téléchargez une liste ou renseignez manuellement vos collaborateurs.")])]),_c('div',{staticClass:"tw-rounded tw-p-3 tw-text-center tw-flex tw-flex-col tw-cursor-pointer tw-w-full",staticStyle:{"max-width":"412px"},style:({ 
              border: `1px solid ${ _vm.selectedInvitationMethod === 'select' ? _vm.theme.ctaBgColor : 'black'  }`,
              backgroundColor: `${ _vm.selectedInvitationMethod === 'select' ? _vm.theme.ctaBgColor : 'transparent' }`,
              color: _vm.selectedInvitationMethod === 'select' ? `#FFFFFF` : `#333333`
            }),on:{"click":function($event){_vm.selectedInvitationMethod = 'select'}}},[_c('span',{staticClass:"tw-font-semibold"},[_vm._v("Sélectionnez vos collaborateurs")]),_c('span',{staticClass:"tw-mt-1 tw-text-sm"},[_vm._v("Choisissez des collaborateurs parmi ceux déjà présent dans la plateforme.")])])]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center tw-mt-14 tw-gap-4"},[_c('v-btn',{staticClass:"tw-text-white",style:({ border: `1px solid ${_vm.theme.ctaBgColor}` }),attrs:{"elevation":"0","type":"Modifier","outlined":"","rounded":""},on:{"click":function($event){return _vm.$router.push({ name: 'Elearning.FormationType.Select' })}}},[_c('span',{staticClass:"tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4",style:({ color: _vm.theme.ctaBgColor })},[_vm._v("Précédent")])]),_c('v-btn',{staticClass:"tw-text-white",style:({ backgroundColor: `${_vm.theme.ctaBgColor}` }),attrs:{"elevation":"0","type":"Modifier","rounded":"","disabled":!_vm.selectedInvitationMethod},on:{"click":function($event){return _vm.$router.push({ name: _vm.nextPathName })}}},[_c('span',{staticClass:"tw-text-xs tw-font-semibold tw-tracking-normal tw-px-6"},[_vm._v("Suivant")])])],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }