<template>
  <v-container class="tw-pt-0 tw-mt-0 tw-relative">
    <div class="auth-container">
      <TitleComponent
        class="public-title-auth"
        text="Quelle offre pour votre entreprise ?"
      />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-6">
        Parce que vous avez renseigné un nombre de
        <b>{{ numberSalary }} salariés</b>, voici l’offre qui correspond le
        mieux à la taille de votre entreprise.<br />
        <span v-if="referer.slug !== 'gpmse'">
          Pour accéder gratuitement au module d’évaluation de la sous-traitance,
          vous devez préalablement renseigner une option de paiement.
        </span>
      </SignupSecondaryTextComponent>
      <div class="tw-flex tw-flex-col tw-items-center">
        <SignupSecondaryTextComponent class="public-text-register tw-mt-6 tw-text-center">
          <b>
            Sélectionnez l’offre la plus adaptée à votre entreprise puis cliquez
            sur “Suivant” et laissez-vous guider.
          </b>
        </SignupSecondaryTextComponent>
        <content-placeholders v-if="isPlansLoading">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
        <div v-else class="tw-grid tw-grid-cols-1 tw-w-full tw-mt-6">
          <RadioButtonComponent :select="form.engagement" :items="engagementItems" @change="onengagementChanged" />
          <RadioButtonComponent v-if="referer.slug !== 'gpmse'" class="!tw-mt-0" :select="form.regulation" :items="regulationItems"
            @change="onregulationChanged" />
        </div>

        <div class="tw-my-12 tw-flex tw-flex-row tw-items-end plans-container" :style="{ height: referer.slug !== 'gpmse' ? '400px' : '350px' }">
          <div v-for="item in offers" :key="item.id" @click="choseOneOffer(item)">
            <OfferItemComponent :offer="item" :selected-offer="selectedOffer" :selected-regulation="form.regulation"
              :selected-engagement="form.engagement" />
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-justify-end tw-mb-8 tw-flex-col md:tw-flex-row"
      >
        <PrimaryButtonComponent
          :loading="isSendingForm"
          :disabled="!formValid || isSendingForm"
          type="submit"
          :style="{
            backgroundColor: `${referer.theme.ctaBgColor} !important`,
            color: `${referer.theme.ctaTextColor} !important`,
          }"
          @click="submit"
          class="mb-2"
        >
          Suivant
          <svg class="tw-ml-3" width="16" height="12" viewBox="0 0 16 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6H15" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 1L15 6L10 11" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </PrimaryButtonComponent>
      </div>
      <p class="registration-iml tw-relative">
        Les informations à caractère personnel recueillies dans le cadre de
        votre demande de souscription à la plateforme Viqtor® sont enregistrées
        dans un fichier informatisé par la société DBWO pour permettre la
        finalisation de l’inscription de votre organisation à nos services.
        Elles seront conservées jusqu’à la validation de la souscription,
        période durant laquelle elles pourront être régulièrement mises à jour à
        votre demande. Hors demande de votre part, elles seront détruites en cas
        de non-finalisation de votre demande sous un délai de 2 mois après cet
        envoi. Ces données sont destinées au personnel administratif de DBWO et
        conservées sur les serveurs de l’entreprise OVH qui héberge le site
        Viqtor® sur le territoire français. Conformément à la loi « informatique
        et libertés », vous pouvez exercer votre droit d'accès aux données vous
        concernant et les faire rectifier en contactant : dpo@dbwo.legal.
      </p>
    </div>
  </v-container>
</template>

<script>
import OfferItemComponent from "@/features/auth/components/OfferItemComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import {
  choseOfferPlan,
  getPlansByParams,
  choseOfferPlanIron,
} from "@/features/auth/services/appApi";
import RadioButtonComponent from "@/components/common/RadioButtonComponent";

export default {
  name: "SetupAccountCompanyPage",
  components: {
    RadioButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
    PrimaryButtonComponent,
    OfferItemComponent,
  },
  computed: {
    numberSalary() {
      if (this.$store.getters.getAuthUser) {
        return this.$store.getters.getAuthUser.workInCompany ? this.$store.getters.getAuthUser.workInCompany.nbrEmployees : 0;
      } else {
        return 0;
      }
    },
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
    dashboardName() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.user.expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    }
  },
  data() {
    return {
      formValid: false,
      isSendingForm: false,
      isPlansLoading: false,
      selectedOffer: {},
      form: {
        regulation: "monthly",
        engagement: 24,
      },
      offers: [],
      engagementItems: [
        {
          value: 12,
          label: "Engagement 1 an",
        },
        {
          value: 24,
          label: "Engagement 2 ans",
        },
      ],
      regulationItems: [
        {
          value: "yearly",
          label: "Règlement annuel",
        },
        {
          value: "monthly",
          label: "Règlement mensuel",
        },
      ],
    };
  },
  mounted() {
    this.getPlansByParams();
  },
  methods: {
    onregulationChanged(value) {
      this.form.regulation = value;
      this.getPlansByParams();
    },
    onengagementChanged(value) {
      this.form.engagement = value;
      this.getPlansByParams();
    },
    getPlansByParams() {
      getPlansByParams(this.form)
        .then((response) => {
          let arr = [];
          const list = response.data.data;
          list.forEach((item, index) => {
            if (index === 0) {
              item.isFirst = true;
              item.isLast = false;
            } else if (index === list.length - 1) {
              item.isFirst = false;
              item.isLast = true;
            } else {
              item.isFirst = false;
              item.isLast = false;
            }
            arr.push(item);
          });
          this.offers = arr.filter(offer => !!offer.plan);
        })
        .catch((err) => console.log(err))
        .finally(() => (this.isPlansLoading = false));
    },
    choseOneOffer(offer) {
      this.selectedOffer = offer;
      this.formValid = true;
    },
    submit() {
      this.isSendingForm = true;
      if (this.referer.title === "GDPR assist") {
        choseOfferPlanIron(this.selectedOffer.id, {
          engagement: this.form.engagement,
          regulation: this.form.regulation,
        })
          .then(() => {
            this.$store.dispatch("setToken", this.$store.state.token);
            this.$router.push({ name: "Company.DashboardPage" });
          })
          .catch((err) => console.log(err))
          .finally(() => (this.isSendingForm = false));
      } else {
        choseOfferPlan(this.selectedOffer.id)
          .then((response) => {
            if (response.data.data.ignorePayment) {
              if (this.referer.slug === "gpmse")
                this.$store.commit('SET_GPMSE_SHOW_DASHBOARD_REDIRECTION_POPUP', true);
              
              this.$router.push({ name: this.dashboardName });
            } else {
              this.$router.push({
                name: "SetupAccountPricingCompany",
                params: { planId: this.selectedOffer.id },
              });
            }
          })
          .catch((err) => console.log(err))
          .finally(() => (this.isSendingForm = false));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.plans-container {
  width: 100%;

  height: 400px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--cta-bg-color);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
</style>
