<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" persistent width="40%" content-class="rounded-xl">
    <div class="tw-pt-11 tw-px-9 tw-pb-6">
      <h3 class="tw-font-semibold tw-text-2xl tw-text-center">Votre document va être renvoyé à cette liste</h3>
    </div>

    <div class="tw-px-10 tw-h-96 tw-overflow-y-auto tw-mx-auto" style="width: 75%;">
      <ul>
        <li 
          v-for="receiver in receivers" :key="receiver.id"
          class="tw-flex tw-justify-between tw-py-3 tw-border-b tw-border-black tw-text-xs tw-font-medium"
        >
          <span>{{ receiver.firstName }}</span> 
          <span>{{ receiver.lastName }}</span> 
          <span>{{ receiver.email }}</span>
        </li>
      </ul>
    </div>

    <div class="tw-flex tw-justify-center tw-gap-x-12 tw-mb-6 tw-mt-9">
      <v-btn width="160px" outlined @click="$emit('close')" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs">Fermer</span></v-btn>
      <v-btn width="160px" @click="$emit('sendToAll')" elevation="0" rounded :color="theme.ctaBgColor"><span class="tw-text-xs tw-text-white">Envoyer</span></v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'SignatureSuccessPopup',
  props: {
    canOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      receivers: [
        { id: 1, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 2, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 3, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 4, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 5, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 6, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 7, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 8, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 9, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 10, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 11, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 12, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 13, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 14, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 15, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 16, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 17, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 18, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 19, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
        { id: 20, firstName: "Pierre", lastName: "Mariel", email: "mariel@gmail.com" },
      ],
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  watch: {
    canOpen: { handler(val) { this.open = val; }, immediate: true },
  },
}
</script>

<style>
.company-text-field.custom-text-field.v-text-field input {
  padding: 0 1rem !important;
  height: 40px !important;
}
</style>